var MLCV = MLCV || {};

var mlLinkModule = function () {
    var _self = this;

    return _self;
};
var vimeoUrlParam = "?background=1&loop=0&autoplay=0&playsinline=0&muted=0&dnt=1";
var VIEWPORT_CACHE = (function () {
    var _viewPort = {
        width: jQuery(window).width(),
        height: jQuery(window).height()
    }, getWidth, setWidth, getHeight, setHeight, compareWidth, compareHeight;

    getWidth = function () {
        return _viewPort.width;
    };

    setWidth = function (param) {
        _viewPort.width = param;
    };

    getHeight = function () {
        return _viewPort.height;
    };

    setHeight = function (param) {
        _viewPort.height = param;
    };

    isSameDimension = function (currentWidth, currentHeight) {
        return _viewPort.width === currentWidth && _viewPort.height === currentHeight;
    };

    return {
        getWidth: getWidth,
        setWidth: setWidth,
        getHeight: getHeight,
        setHeight: setHeight,
        isSameDimension: isSameDimension
    };
}());
var mlMediaElementPluginHandler = function (parent) {
    var _self = this;

    _self.parent = parent;
    _self.loadDefaults = function () {
        _self.mediaelementplayerConfig = {
            // if the <video width> is not specified, this is the default
            defaultVideoWidth: 480,
            // if the <video height> is not specified, this is the default
            defaultVideoHeight: 270,
            // width of audio player
            audioWidth: '100%',
            // height of audio player
            audioHeight: 30,
            // initial volume when the player starts
            startVolume: 0.8,
            // useful for <audio> player loops
            loop: false,
            // enables Flash and Silverlight to resize to content size
            enableAutosize: true,
            // the order of controls you want on the control bar (and other plugins below)
            features: ['playpause', 'progress', 'current', 'duration', 'tracks', 'volume', 'fullscreen'],
            // Hide controls when playing and mouse is not over the video
            alwaysShowControls: true,
            // force iPad's native controls
            iPadUseNativeControls: false,
            // force iPhone's native controls
            iPhoneUseNativeControls: false,
            // force Android's native controls
            AndroidUseNativeControls: false,
            // forces the hour marker (##:00:00)
            alwaysShowHours: false,
            // show framecount in timecode (##:00:00:00)
            showTimecodeFrameCount: false,
            // used when showTimecodeFrameCount is set to true
            framesPerSecond: 25,
            // turns keyboard support on and off for this instance
            enableKeyboard: true,
            // when this player starts, it will pause other players
            pauseOtherPlayers: true,
            // array of keyboard commands
            keyActions: [],
            plugins: ['youtube', 'flash', 'silverlight', 'vimeo'],
            autosizeProgress: true,
            success: function (mElement, oNode) {
                var selector = "#" + mElement.id;

                if (isMobile.any) {
                    var parentStackedHolder = jQuery(oNode).closest('.stacked-media-module-main-holder, .ml-media-block');
                    if (parentStackedHolder.length < 1 && !parentStackedHolder.find('.ml-wffm data.modal.component-level').length < 1)
                        return;

                    jQuery(parentStackedHolder).find('.mejs-button.mejs-playpause-button, .mejs__button.mejs__playpause-button').click(function (e) {
                        MLCV.CarouselModule.autoScrollStop();
                    });
                    
                    jQuery(parentStackedHolder).find('.mejs__overlay-button', 'mejs__overlay.mejs__layer.mejs__overlay-play').click(function (e) {
                        MLCV.CarouselModule.autoScrollStop();
                    });
                    
                    mElement.addEventListener('play', function (e) {
                        jQuery(selector + ".mejs__overlay.mejs__layer.mejs__overlay-play").css("display", "none");
                    }, false);

                    mElement.addEventListener('pause', function (e) {
                        jQuery(selector + ".mejs__overlay.mejs__layer.mejs__overlay-play").css("display", "block");
                    });
                }
            }
        }
    };
    _self.autoPlay = function () {
        return function (element, oNode) {
            if (element.pluginType == 'flash') {
                element.addEventListener('canplay', function () {
                    element.play();
                }, false);
            } else {
                element.play();
            }
            MLCV.WebAnalytics.GA.TrackMediaPlay(oNode);
        };
    };
    _self.initializePlugin = function (targetElement) {
        var isAutoPlay = arguments[1];

        //check if video is inside carouselInstance
        //if ((jQuery(targetElement[0]).closest(".ml-carousel-item").length !== 0) && (isMobile.any)) {
        //    _self.mediaelementplayerConfig.features = ['playpause', 'progress', 'current', 'duration', 'tracks', 'volume'];
        //} else {
        //    _self.mediaelementplayerConfig.features = ['playpause', 'progress', 'current', 'duration', 'tracks', 'volume', 'fullscreen'];
        //}

        if (isAutoPlay == true) {
            _self.mediaelementplayerConfig.success = _self.autoPlay();
        }

        var mediaElement = new MediaElementPlayer(targetElement[0], _self.mediaelementplayerConfig);
        mediaElement.resetSize();
        return mediaElement;
    };

    _self.loadDefaults();

    return _self;
};
var mlNativeMediaHandler = function () {
    var _self = this;

    _self.helpers = new mlHelpers();
    _self.mediaElementPluginHandler = new mlMediaElementPluginHandler(_self);
    _self.transform = function (mediaType, mediaLink, mediaSource, mediaContainer) {
        var media;
        var getMediaForVideo = function () {
            var source = jQuery('<source>').attr({
                src: mediaLink,
                type: 'video/mp4'
            });
            media = jQuery('<video>').attr({
                width: '100%',
                height: '100%'
            }).append(source);

            if (_self.helpers.isYoutubeUrl(mediaLink)) {
                var url = _self.helpers.getYoutubeUrlForMediaElements(mediaLink);
                source = jQuery('<source>').attr({
                    src: url,
                    type: 'video/youtube'
                });

                media = jQuery('<video>').attr({
                    width: '100%',
                    height: '100%',
                    id: 'mlPlayer' + ((Math.random() * 100).toFixed(0) * (Math.random() * 10)).toFixed(0)
                }).append(source);
            }

            if (_self.helpers.isVimeoUrl(mediaLink)) {
                //var url = mediaLink;
                //if (mediaContainer.hasClass('js-inside-carousel')) { 
                //    url = url + "?background=1&loop=0&autoplay=0&playsinline=0&muted=0";
                //} else {
                //    url = url + "?background=1&loop=0&autoplay=0&playsinline=0&muted=0";
                //}
                var url = _self.helpers.getVimeoUrlForMediaElements(mediaLink);
                source = jQuery('<source>').attr({
                    src: url,
                    type: 'video/vimeo'
                });

                media = jQuery('<video>').attr({
                    width: '100%',
                    height: '100%',
                    id: 'mlPlayer' + ((Math.random() * 100).toFixed(0) * (Math.random() * 10)).toFixed(0)
                }).append(source);
            }
        };
        var getMediaForAudio = function () {
            var source = jQuery('<source>').attr({
                src: mediaLink,
                type: 'audio/mp3'
            });
            media = jQuery('<audio>').attr({
                width: '100%',
                height: '100%'
            }).append(source);
        };
        var getMediaForImage = function () {
            media = jQuery('<img>').attr({
                alt: mediaLink,
                src: mediaLink,
                width: '100%',
                height: '100%'
            });
        };

        if (mediaType === "video") {
            getMediaForVideo();
        } else if (mediaType === "audio") {
            getMediaForAudio();
        } else if (mediaType === "photo" || mediaType === "image") {
            getMediaForImage();
            mediaContainer.css('height', 'auto');
            mediaContainer.css('width', '100%');
        }

        mediaContainer.append(media);
        jQuery('.ml-publication-media[data-media-type="video"]').append(mediaContainer);
        //  if (mediaType == 'video') {
        //     if (mediaContainer.find(media).closest(".ml-carousel-item").length !== 0) {
        //         if (_self.helpers.isYoutubeUrl(mediaLink)) {
        //            media.attr("playsinline", false);
        //         }
        //     }
        //  }

        if (mediaType == 'video' || mediaType == 'audio') {
            return _self.mediaElementPluginHandler.initializePlugin(mediaContainer.find(media));
        }

        return {};
    };

    return _self;
};
var mlHelpers = function () {
    var _self = this;

    this.removeDotNotation = function (string) {
        if (typeof string === 'string') {
            return string.replace(/\./g, ' ');
        } else {
            return '';
        }
    };
    this.isDesktopView = function () {
        return (window.innerWidth > 768);
    };
    this.isTabletView = function () {
        return (window.innerWidth <= 768);
    };
    this.isMobileView = function () {
        return (window.innerWidth <= 640);
    };
    this.isSmallMobileView = function () {
        return (window.innerWidth <= 500);
    };

    this.isVimeoUrl = function (string) {
        if (typeof string === 'string') {
            return string.match(/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i);
        } else {
            return false;
        }
    };
    
    this.getVimeoUrlForMediaElements = function (url) {
        //var urlParam = "?background=1&loop=0&autoplay=0&playsinline=0&muted=0&dnt=1";
        return url + vimeoUrlParam;
    };
    this.isYoutubeUrl = function (string) {
        if (typeof string === 'string') {
            return string.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/);
        } else {
            return false;
        }
    };
    this.getYoutubeUrlForMediaElements = function (url) {
        if (url.indexOf("watch?v") !== -1) {
            url = url.split('&');
            url = url[0];
        } else if (url.indexOf("/embed/") !== -1) {
            url = url.split('?');
            url = url[0];
            url = url.replace("embed/", "watch?v=");
        }

        return url;
    };
    this.toNumeric = function (string) {
        if (typeof string === 'string') {
            return string.replace(/\px/g, ' ');
        } else {
            return '';
        }
    };
    this.isPageEditingMode = function () {
        if (typeof Sitecore !== 'undefined') {
            if (typeof Sitecore.PageModes !== 'undefined') {
                if (typeof Sitecore.PageModes.PageEditor !== 'undefined') {
                    return Sitecore.PageModes.PageEditor.editing();
                }
            }
        }

        return false;
    };

    return _self;
};
var mlMediaModule = function () {
    var _self = this;
    _self.helpers = new mlHelpers();
    var mediaTypeHandler = function () {
        var _obj = this;

        _obj.isAudioType = function (stackedMediaInstance) {
            var audioUrl = stackedMediaInstance.data('audioUrl');

            return audioUrl;
        };
        _obj.isImageType = function (stackedMediaInstance) {
            var imageUrl = stackedMediaInstance.find('img[dataimagetype="actual"]').attr('src');

            return imageUrl;
        };
        _obj.isVideoType = function (stackedMediaInstance) {
            var videoUrl = stackedMediaInstance.data('videoUrl');

            return videoUrl;
        };
        _obj.getAudioAttributes = function (stackedMediaInstance, mediaAttributes) {
            mediaAttributes.mediaType = "audio";
            mediaAttributes.mediaLink = stackedMediaInstance.data('audioUrl');

            return mediaAttributes;
        };
        _obj.getImageAttributes = function (stackedMediaInstance, mediaAttributes) {
            mediaAttributes.mediaType = "image";
            mediaAttributes.mediaLink = stackedMediaInstance.find('img[dataimagetype="actual"]').attr('src');

            return mediaAttributes;
        };
        _obj.getVideoAttributes = function (stackedMediaInstance, mediaAttributes) {
            mediaAttributes.mediaType = "video";
            mediaAttributes.mediaLink = stackedMediaInstance.data('videoUrl');

            return mediaAttributes;
        };
        _obj.getMediaAttributes = function (stackedMediaInstance) {
            var mediaAttributes = {};
            mediaAttributes.mediaTitle = stackedMediaInstance.data('mediaTitle');
            mediaAttributes.mediaDescription = stackedMediaInstance.data('mediaDesc');
            mediaAttributes.ctaLink = stackedMediaInstance.data('ctaLink');
            mediaAttributes.ctaText = stackedMediaInstance.data('ctaText');
            mediaAttributes.mediaType = "video";
            mediaAttributes.mediaLink = '';
            mediaAttributes.mediaSource = '';
            mediaAttributes.mediaIndex = stackedMediaInstance.data('itemIndex');

            if (_obj.isVideoType(stackedMediaInstance)) {
                mediaAttributes = _obj.getVideoAttributes(stackedMediaInstance, mediaAttributes);
            } else if (_obj.isAudioType(stackedMediaInstance)) {
                mediaAttributes = _obj.getAudioAttributes(stackedMediaInstance, mediaAttributes);
            } else if (_obj.isImageType(stackedMediaInstance)) {
                mediaAttributes = _obj.getImageAttributes(stackedMediaInstance, mediaAttributes);
            }

            return mediaAttributes;
        };

        return _obj;
    };
    var eventHandlers = function (parent) {
        _obj = jQuery(this);

        _obj.parent = parent;
        _obj.mlLightBox = new mlLightBox();
        _obj.onResize = {};
        _obj.onResize.init = function () {
            _obj.onResize.setMobilePlayerSize();
            jQuery(window).on('resize', function () {
                _obj.onResize.setMobilePlayerSize();
            });
        };
        _obj.onResize.setMobilePlayerSize = function () {
            jQuery('.ml-responsive-media').each(function (index) {
                var stackedMediaInstance = jQuery(this);
                var parentContainer = stackedMediaInstance.parent();
                var element = stackedMediaInstance.data('mediaElement');

                if (parentContainer.hasClass('stacked-media-module-selection')) {
                    if (element.$media) {
                        element.resetSize();
                    }
                    return;
                }

                if (parentContainer.width() > 360) {
                    stackedMediaInstance.css({
                        'width': parentContainer.width(),
                        'max-width': 420,
                        'height': 300,
                        'min-height': 300,
                        'max-height': '100%'
                    });
                } else {
                    stackedMediaInstance.css({
                        'width': 1200,
                        'max-width': '100%',
                        'height': '100%',
                        'min-height': 120,
                        'max-height': '100%'
                    });
                }

                if (element.$media) {
                    element.resetSize();
                }
            });
        };
        _obj.onClick = {};
        _obj.onClick.openLightBoxModal = function (e, element) {
            var stackedMedia = jQuery(element).closest('.stacked-media');
            var mediaAttributes = _obj.parent.mediaTypeHandler.getMediaAttributes(stackedMedia);

            if (_obj.parent.stackedMediaModuleHelper.isStackedMediaSelectionItem(stackedMedia)) {
                var playlistItems = _obj.parent.stackedMediaModuleHelper.getMediaAttributeListByStackedMediaInstance(stackedMedia);
                mediaAttributes = _obj.parent.mediaTypeHandler.getMediaAttributes(stackedMedia);

                _obj.mlLightBox.openWithCarousel(mediaAttributes, playlistItems);
            } else {
                _obj.mlLightBox.open(mediaAttributes.mediaType, mediaAttributes.mediaLink, mediaAttributes.mediaSource, mediaAttributes.mediaTitle, mediaAttributes.mediaDescription, mediaAttributes.ctaLink, mediaAttributes.ctaText);
            }

            return false;
        };
        return _obj;
    };

    _self.stackedMediaModuleHelper = new mlStackedMediaHelper();
    _self.mlLightBox = new mlLightBox();
    _self.mediaTypeHandler = new mediaTypeHandler();
    _self.eventHandlers = new eventHandlers(_self);
    _self.nativeMediaHandler = new mlNativeMediaHandler();
    _self.Init = function () {
        var mainStackedMedia = jQuery(".stacked-media-module>.stacked-media-module-main-holder>.stacked-media");
        var standAloneMedia = jQuery(".stacked-media-module>.stacked-media-module-main-holder>.stacked-media, .stacked-media").not('.ml-playlist-videos-container .stacked-media').not('.ml-carousel .stacked-media');
        var infoGraphs = jQuery(".stacked-media-module .info-graphic-module .info-graphic-module-wrapper");
        var handleMultipleInstance = function (mediaObjects) {
            mediaObjects.each(function (index) {
                var stackedMediaInstance = jQuery(this);
                var mediaAttributes = _self.mediaTypeHandler.getMediaAttributes(stackedMediaInstance);
                var mediaContainer = '';

                if (!stackedMediaInstance.hasClass('ml-transformed')) {
                    if (_self.helpers.isMobileView()) {
                        stackedMediaInstance.addClass('ml-responsive-media');
                        var mediaElement = {};
                        if (_self.mediaTypeHandler.isAudioType(stackedMediaInstance)) {
                            stackedMediaInstance.find(".audio-with-thumbnail .stacked-media-module-overlay-wrapper img").addClass("hidden");
                            stackedMediaInstance.find(".audio-iframe").removeClass("hidden");
                            mediaContainer = stackedMediaInstance.find(".audio-iframe");
                            mediaElement = _self.nativeMediaHandler.transform(mediaAttributes.mediaType, mediaAttributes.mediaLink, mediaAttributes.mediaSource, mediaContainer);
                        } else if (_self.mediaTypeHandler.isVideoType(stackedMediaInstance)) {

                            stackedMediaInstance.find(".video-with-thumbnail").addClass("hidden");
                            stackedMediaInstance.find(".video-iframe").removeClass("hidden");
                            mediaContainer = stackedMediaInstance.find(".video-iframe");
                            mediaElement = _self.nativeMediaHandler.transform(mediaAttributes.mediaType, mediaAttributes.mediaLink, mediaAttributes.mediaSource, mediaContainer);
                        } else {
                            // photo/image
                        }
                        stackedMediaInstance.data('mediaElement', mediaElement);
                    } else {
                        //video
                        stackedMediaInstance.find(".video-iframe").remove();
                        stackedMediaInstance.find(".video-with-thumbnail").removeClass("hidden");
                        //audio
                        stackedMediaInstance.find(".audio-iframe").remove();
                        stackedMediaInstance.find(".audio-with-thumbnail").removeClass("hidden");

                        if (stackedMediaInstance.hasClass('info-graphic-module-wrapper')) {
                            stackedMediaInstance.off('click');
                            stackedMediaInstance.click(function (e) {
                                return _self.eventHandlers.onClick.openLightBoxModal(e, jQuery(this));
                            });
                        } else {
                            stackedMediaInstance.find('a').click(function (e) {
                                return _self.eventHandlers.onClick.openLightBoxModal(e, jQuery(this));
                            });
                        }
                    }

                    stackedMediaInstance.addClass('ml-transformed');
                }
            });
        };

        handleMultipleInstance(mainStackedMedia);
        handleMultipleInstance(standAloneMedia);
        handleMultipleInstance(infoGraphs);
        _self.eventHandlers.onResize.init();
    };

    return _self;
};
var mlStackedMediaHelper = function () {
    var _self = this;
    var mediaTypeHandler = function () {
        var _obj = this;

        _obj.isAudioType = function (stackedMediaInstance) {
            var audioUrl = stackedMediaInstance.data('audioUrl');

            return audioUrl;
        };
        _obj.isImageType = function (stackedMediaInstance) {
            var imageUrl = stackedMediaInstance.find('img[dataimagetype="actual"]').attr('src');

            return imageUrl;
        };
        _obj.isVideoType = function (stackedMediaInstance) {
            var videoUrl = stackedMediaInstance.data('videoUrl');

            return videoUrl;
        };
        _obj.getAudioAttributes = function (stackedMediaInstance, mediaAttributes) {
            mediaAttributes.mediaType = "audio";
            mediaAttributes.mediaLink = stackedMediaInstance.data('audioUrl');

            return mediaAttributes;
        };
        _obj.getImageAttributes = function (stackedMediaInstance, mediaAttributes) {
            mediaAttributes.mediaType = "image";
            mediaAttributes.mediaLink = stackedMediaInstance.find('img[dataimagetype="actual"]').attr('src');

            return mediaAttributes;
        };
        _obj.getVideoAttributes = function (stackedMediaInstance, mediaAttributes) {
            mediaAttributes.mediaType = "video";
            mediaAttributes.mediaLink = stackedMediaInstance.data('videoUrl');

            return mediaAttributes;
        };
        _obj.getMediaAttributes = function (stackedMediaInstance) {
            var mediaAttributes = {};
            mediaAttributes.mediaTitle = stackedMediaInstance.data('mediaTitle');
            mediaAttributes.mediaDescription = stackedMediaInstance.data('mediaDesc');
            mediaAttributes.ctaLink = stackedMediaInstance.data('ctaLink');
            mediaAttributes.ctaText = stackedMediaInstance.data('ctaText');
            mediaAttributes.mediaType = "video";
            mediaAttributes.mediaLink = '';
            mediaAttributes.mediaSource = '';

            if (_obj.isVideoType(stackedMediaInstance)) {
                mediaAttributes = _obj.getVideoAttributes(stackedMediaInstance, mediaAttributes);
            } else if (_obj.isAudioType(stackedMediaInstance)) {
                mediaAttributes = _obj.getAudioAttributes(stackedMediaInstance, mediaAttributes);
            } else if (_obj.isImageType(stackedMediaInstance)) {
                mediaAttributes = _obj.getImageAttributes(stackedMediaInstance, mediaAttributes);
            }

            return mediaAttributes;
        };

        return _obj;
    };

    _self.mediaTypeHandler = new mediaTypeHandler();
    _self.loadDefaults = function () {
        _self.parentClass = '.stacked-media-module';
        _self.childClass = {
            featured: {
                container: '.stacked-media-module-main-holder'
            },
            selection: {
                container: '.stacked-media-module-selection-holder',
                main: '.stacked-media-module-selection',
                item: '.stacked-media-module-selection-item'
            },
            item: '.stacked-media'
        };
    };
    _self.isStackedMediaSelectionItem = function (stackedMediaInstance) {
        var parentElement = stackedMediaInstance.closest(_self.parentClass);

        return (parentElement.length == 1);
    };
    _self.getStackedMediaParentContainer = function (stackedMediaInstance) {
        var parentElement = stackedMediaInstance.closest(_self.parentClass);

        return parentElement;
    };
    _self.getMediaAttributeListByStackedMediaInstance = function (stackedMediaInstance) {
        var stackedMediaModuleInstance = _self.getStackedMediaParentContainer(stackedMediaInstance);
        var mediaAttributeList = [];
        var mediaItems = stackedMediaModuleInstance.find(_self.childClass.item);

        mediaItems.each(function (index) {
            var mediaBoxContianerInstance = jQuery(this);
            mediaBoxContianerInstance.data('itemIndex', index);
            var mediaAttribute = _self.mediaTypeHandler.getMediaAttributes(mediaBoxContianerInstance);

            mediaAttribute.mediaIndex = index;
            mediaAttributeList.push(mediaAttribute);
        });

        return mediaAttributeList;
    };

    _self.loadDefaults();

    return _self;
};
var mlCarousel = function () {
    var _self = this;
    var _publicationModuleHandlers = function (parent) {
        var _obj = this;
        var _templates = function (parent) {
            var __obj = this;

            __obj.parent = parent;
            __obj.nativeMediaHandler = new mlNativeMediaHandler();
            __obj.loadDefaults = function () {
                __obj.parentClass = '.ml-publication-media-instance';
                __obj.childClass = {
                    audioIframeHolder: '.audio-iframe',
                    videoIframeHolder: '.video-iframe',
                    audioThumbnailHolder: '.audio-with-thumbnail',
                    videoThumbnailHolder: '.video-with-thumbnail',
                    photoThumbnailHolder: '.photo-with-thumbnail',
                    leftphotoThumbnailHolder: '.leftphoto-with-thumbnail',
                    rightphotoThumbnailHolder: '.rightphoto-with-thumbnail',
                    mediaThumbnailImage: '.img-thumbnail',
                    mediaOverlay: '.ml-overlay',
                    mediaOverlayIcon: '.playIcon',
                    mediaOverlayIconWrapper: '.ml-overlay-wrapper',
                };
                __obj.resources = {};
                __obj.resources.playIcon = '/~/media/MorganLewisCareers/MediaPlayer/play-icon.ashx';
                __obj.resources.zoomIcon = '/~/media/MorganLewisCareers/MediaPlayer/play-icon.ashx';
            };
            __obj.getAudioDisplayByAttributes = function (publicationMediaAttributes) {
                var tooltiptext = publicationMediaAttributes.mediaSource.length > 0 ? publicationMediaAttributes.mediaSource : publicationMediaAttributes.mediaRedirect;
                var main = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.parentClass));
                var mediaThumbnailHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.audioThumbnailHolder));
                var mediaThumbnailImage = jQuery('<img>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaThumbnail });
                var mediaOverlay = jQuery('<a>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlay)).attr({ href: tooltiptext });
                var mediaOverlayIcon = jQuery('<img>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIcon)).attr({ src: __obj.resources.playIcon });
                var mediaOverlayIconWrapper = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIconWrapper));
                var mediaHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.audioIframeHolder));
                var template = '';

                mediaOverlayIconWrapper.append(mediaOverlayIcon);
                mediaOverlay.append(mediaOverlayIconWrapper);
                mediaThumbnailHolder.append(mediaThumbnailImage, mediaOverlay);

                if (isMobile.any) {
                    mediaThumbnailHolder.addClass('hidden');
                    __obj.nativeMediaHandler.transform(publicationMediaAttributes.mediaType, publicationMediaAttributes.mediaSource, '', mediaHolder);
                } else {
                    mediaThumbnailHolder.removeClass('hidden');
                }

                template = main.append(mediaThumbnailHolder, mediaHolder);

                return template;
            };
            __obj.getVideoDisplayByAttributes = function (publicationMediaAttributes) {
                var tooltiptext = publicationMediaAttributes.mediaSource.length > 0 ? publicationMediaAttributes.mediaSource : publicationMediaAttributes.mediaRedirect;
                var main = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.parentClass));
                var mediaThumbnailHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.videoThumbnailHolder));
                var mediaThumbnailImage = jQuery('<img>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaThumbnail, alt: publicationMediaAttributes.mediaToolTipText });
                var mediaOverlay = jQuery('<a>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlay)).attr({ href: tooltiptext });
                var mediaOverlayIcon = jQuery('<img>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIcon)).attr({ src: __obj.resources.playIcon, alt: "play" });
                var mediaOverlayIconWrapper = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIconWrapper));
                var mediaHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.videoIframeHolder));//.addClass("js-inside-carousel");
                var template = '';

                if (!_obj.helpers.hasMediaRedirect(publicationMediaAttributes)) {
                    mediaOverlayIconWrapper.append(mediaOverlayIcon);
                }
                mediaOverlay.append(mediaOverlayIconWrapper);
                mediaThumbnailHolder.append(mediaThumbnailImage, mediaOverlay);

                if (isMobile.any) {
                    if (_obj.helpers.hasMediaRedirect(publicationMediaAttributes)) {
                        mediaThumbnailHolder.removeClass('hidden');
                        mediaHolder.addClass('hidden');
                    } else {
                        mediaThumbnailHolder.addClass('hidden');
                    }
                    __obj.nativeMediaHandler.transform(publicationMediaAttributes.mediaType, publicationMediaAttributes.mediaSource, '', mediaHolder);
                } else {
                    mediaThumbnailHolder.removeClass('hidden');
                }

                template = main.append(mediaThumbnailHolder, mediaHolder);

                return template;
            };
            __obj.getPhotoDisplayByAttributes = function (publicationMediaAttributes) {
                var tooltiptext = publicationMediaAttributes.mediaSource.length > 0 ? publicationMediaAttributes.mediaSource : publicationMediaAttributes.mediaRedirect;
                var main = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.parentClass));
                var mediaThumbnailHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.photoThumbnailHolder));
                var mediaThumbnailImage = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaThumbnail, title: publicationMediaAttributes.mediaToolTipText });
                var mediaOverlay = jQuery('<a>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlay)).attr({ href: tooltiptext });
                var mediaOverlayIcon = jQuery('<img>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIcon)).attr({ src: __obj.resources.playIcon });
                var mediaOverlayIconWrapper = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIconWrapper)).attr({ title: publicationMediaAttributes.mediaToolTipText });
                var mediaHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.videoIframeHolder));
                var template = '';
                var src = publicationMediaAttributes.mediaThumbnail;
                if (publicationMediaAttributes.mediaType == "image") {
                    mediaHolder.addClass('hidden');
                }
                mediaThumbnailImage.css({
                    'background-image': 'url("' + src + '")',
                    'background-repeat': 'no-repeat',
                    'background-size': 'contain',
                    'background-position': 'center center'
                });

                if (isMobile.any) {
                    if (_obj.mlHelpers.isMobileView()) {
                        var mediaThumbnailImage = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaMobileImage, title: publicationMediaAttributes.mediaToolTipText });
                        var src = publicationMediaAttributes.mediaMobileImage;
                        mediaThumbnailImage.css({
                            'background-image': 'url("' + src + '")',
                            'background-repeat': 'no-repeat',
                            'background-size': 'contain',
                            'background-position': 'center center'
                        })
                    }
                }
                mediaOverlay.append(mediaOverlayIconWrapper);
                if (_obj.helpers.hasMediaRedirect(publicationMediaAttributes)) {
                    mediaThumbnailHolder.append(mediaThumbnailImage, mediaOverlay);
                } else {
                    mediaThumbnailHolder.append(mediaThumbnailImage);
                }

                template = main.append(mediaThumbnailHolder, mediaHolder);

                return template;
            };
            __obj.getLeftPhotoDisplayByAttributes = function (publicationMediaAttributes) {
                var tooltiptext = publicationMediaAttributes.mediaSource.length > 0 ? publicationMediaAttributes.mediaSource : publicationMediaAttributes.mediaRedirect;
                var main = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.parentClass));
                var mediaThumbnailHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.leftphotoThumbnailHolder));
                var mediaThumbnailImage = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaThumbnail, title: publicationMediaAttributes.mediaToolTipText });
                var mediaOverlay = jQuery('<a>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlay)).attr({ href: tooltiptext, tabindex: "-1"});
                var mediaOverlayIcon = jQuery('<img>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIcon)).attr({ src: __obj.resources.playIcon });
                var mediaOverlayIconWrapper = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIconWrapper)).attr({ title: publicationMediaAttributes.mediaToolTipText });
                var mediaHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.videoIframeHolder));
                var template = '';
                var src = publicationMediaAttributes.mediaThumbnail;
                if (publicationMediaAttributes.mediaType == "leftimage") {
                    mediaHolder.addClass('hidden');
                }
                mediaThumbnailImage.css({
                    'background-image': 'url("' + src + '")',
                    'background-repeat': 'no-repeat',
                    'background-size': 'contain',
                    'background-position': 'left'
                });
                if (isMobile.any) {
                    if (_obj.mlHelpers.isMobileView()) {
                        var mediaThumbnailImage = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaMobileImage, title: publicationMediaAttributes.mediaToolTipText });
                        var src = publicationMediaAttributes.mediaMobileImage;
                        mediaThumbnailImage.css({
                            'background-image': 'url("' + src + '")',
                            'background-repeat': 'no-repeat',
                            'background-size': 'contain',
                            'background-position': 'center center'
                        })
                    }
                }
                mediaOverlay.append(mediaOverlayIconWrapper);
                if (_obj.helpers.hasMediaRedirect(publicationMediaAttributes)) {
                    mediaThumbnailHolder.append(mediaThumbnailImage, mediaOverlay);
                } else {
                    mediaThumbnailHolder.append(mediaThumbnailImage);
                }

                template = main.append(mediaThumbnailHolder, mediaHolder);

                return template;
            };
            __obj.getRightPhotoDisplayByAttributes = function (publicationMediaAttributes) {
                var tooltiptext = publicationMediaAttributes.mediaSource.length > 0 ? publicationMediaAttributes.mediaSource : publicationMediaAttributes.mediaRedirect;
                var main = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.parentClass));
                var mediaThumbnailHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.rightphotoThumbnailHolder));
                var mediaThumbnailImage = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaThumbnail, title: publicationMediaAttributes.mediaToolTipText });
                var mediaOverlay = jQuery('<a>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlay)).attr({ href: tooltiptext });
                var mediaOverlayIcon = jQuery('<img>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIcon)).attr({ src: __obj.resources.playIcon });
                var mediaOverlayIconWrapper = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIconWrapper)).attr({ title: publicationMediaAttributes.mediaToolTipText });
                var mediaHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.videoIframeHolder));
                var template = '';
                var src = publicationMediaAttributes.mediaThumbnail;
                if (publicationMediaAttributes.mediaType == "rightimage") {
                    mediaHolder.addClass('hidden');
                }
                mediaThumbnailImage.css({
                    'background-image': 'url("' + src + '")',
                    'background-repeat': 'no-repeat',
                    'background-size': 'contain',
                    'background-position': 'right'
                });
                if (isMobile.any) {
                    if (_obj.mlHelpers.isMobileView()) {
                        var mediaThumbnailImage = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaMobileImage, title: publicationMediaAttributes.mediaToolTipText });
                        var src = publicationMediaAttributes.mediaMobileImage;
                        mediaThumbnailImage.css({
                            'background-image': 'url("' + src + '")',
                            'background-repeat': 'no-repeat',
                            'background-size': 'contain',
                            'background-position': 'center center'
                        })
                    }
                }

                mediaOverlay.append(mediaOverlayIconWrapper);
                if (_obj.helpers.hasMediaRedirect(publicationMediaAttributes)) {
                    mediaThumbnailHolder.append(mediaThumbnailImage, mediaOverlay);
                } else {
                    mediaThumbnailHolder.append(mediaThumbnailImage);
                }

                template = main.append(mediaThumbnailHolder, mediaHolder);

                return template;
            };

            //[JPM]
            __obj.getPDFDisplayByAttributes = function (publicationMediaAttributes) {
                var tooltiptext = publicationMediaAttributes.mediaSource.length > 0 ? publicationMediaAttributes.mediaSource : publicationMediaAttributes.mediaRedirect;
                var main = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.parentClass));
                var mediaThumbnailHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.photoThumbnailHolder));
                var mediaThumbnailImage = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaThumbnail, title: publicationMediaAttributes.mediaToolTipText });
                var mediaOverlay = jQuery('<a>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlay)).attr({ href: tooltiptext });
                var mediaOverlayIcon = jQuery('<img>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIcon)).attr({ src: __obj.resources.playIcon });
                var mediaOverlayIconWrapper = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIconWrapper)).attr({ title: publicationMediaAttributes.mediaToolTipText });
                var mediaHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.videoIframeHolder));
                var template = '';
                var src = publicationMediaAttributes.mediaThumbnail;
                if (publicationMediaAttributes.mediaType == "pdf") {
                    mediaHolder.addClass('hidden');
                }
                mediaThumbnailImage.css({
                    'background-image': 'url("' + src + '")',
                    'background-repeat': 'no-repeat',
                    'background-size': 'contain',
                    'background-position': 'center center'
                });

                if (isMobile.any) {
                        var mediaThumbnailImage = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaMobileImage, title: publicationMediaAttributes.mediaToolTipText });
                        var src = publicationMediaAttributes.mediaMobileImage;
                        mediaThumbnailImage.css({
                            'background-image': 'url("' + src + '")',
                            'background-repeat': 'no-repeat',
                            'background-size': 'contain',
                            'background-position': 'center center'
                        })
                }
                mediaOverlay.append(mediaOverlayIconWrapper);
                if (_obj.helpers.hasMediaRedirect(publicationMediaAttributes)) {
                    mediaThumbnailHolder.append(mediaThumbnailImage, mediaOverlay);
                } else {
                    mediaThumbnailHolder.append(mediaThumbnailImage);
                }

                template = main.append(mediaThumbnailHolder, mediaHolder);

                return template;
            };

            __obj.getLeftImagePDFDisplayByAttributes = function (publicationMediaAttributes) {
                var tooltiptext = publicationMediaAttributes.mediaSource.length > 0 ? publicationMediaAttributes.mediaSource : publicationMediaAttributes.mediaRedirect;
                var main = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.parentClass));
                var mediaThumbnailHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.photoThumbnailHolder));
                var mediaThumbnailImage = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaThumbnail, title: publicationMediaAttributes.mediaToolTipText });
                var mediaOverlay = jQuery('<a>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlay)).attr({ href: tooltiptext });
                var mediaOverlayIcon = jQuery('<img>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIcon)).attr({ src: __obj.resources.playIcon });
                var mediaOverlayIconWrapper = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIconWrapper)).attr({ title: publicationMediaAttributes.mediaToolTipText });
                var mediaHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.videoIframeHolder));
                var template = '';
                var src = publicationMediaAttributes.mediaThumbnail;
                if (publicationMediaAttributes.mediaType == "leftImagePDF") {
                    mediaHolder.addClass('hidden');
                }
                mediaThumbnailImage.css({
                    'background-image': 'url("' + src + '")',
                    'background-repeat': 'no-repeat',
                    'background-size': 'contain',
                    'background-position': 'left'
                });

                if (isMobile.any) {
                        var mediaThumbnailImage = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaMobileImage, title: publicationMediaAttributes.mediaToolTipText });
                        var src = publicationMediaAttributes.mediaMobileImage;
                        mediaThumbnailImage.css({
                            'background-image': 'url("' + src + '")',
                            'background-repeat': 'no-repeat',
                            'background-size': 'contain',
                            'background-position': 'center center'
                        })
                }
                mediaOverlay.append(mediaOverlayIconWrapper);
                if (_obj.helpers.hasMediaRedirect(publicationMediaAttributes)) {
                    mediaThumbnailHolder.append(mediaThumbnailImage, mediaOverlay);
                } else {
                    mediaThumbnailHolder.append(mediaThumbnailImage);
                }

                template = main.append(mediaThumbnailHolder, mediaHolder);

                return template;
            };

            __obj.getRightImagePDFDisplayByAttributes = function (publicationMediaAttributes) {
                var tooltiptext = publicationMediaAttributes.mediaSource.length > 0 ? publicationMediaAttributes.mediaSource : publicationMediaAttributes.mediaRedirect;
                var main = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.parentClass));
                var mediaThumbnailHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.rightphotoThumbnailHolder));
                var mediaThumbnailImage = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaThumbnail, title: publicationMediaAttributes.mediaToolTipText });
                var mediaOverlay = jQuery('<a>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlay)).attr({ href: tooltiptext });
                var mediaOverlayIcon = jQuery('<img>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIcon)).attr({ src: __obj.resources.playIcon });
                var mediaOverlayIconWrapper = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaOverlayIconWrapper)).attr({ title: publicationMediaAttributes.mediaToolTipText });
                var mediaHolder = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.videoIframeHolder));
                var template = '';
                var src = publicationMediaAttributes.mediaThumbnail;
                if (publicationMediaAttributes.mediaType == "rightImagePDF") {
                    mediaHolder.addClass('hidden');
                }
                mediaThumbnailImage.css({
                    'background-image': 'url("' + src + '")',
                    'background-repeat': 'no-repeat',
                    'background-size': 'contain',
                    'background-position': 'right'
                });
                if (isMobile.any) {
                        var mediaThumbnailImage = jQuery('<div>').addClass(MLCV.Helpers.removeDotNotation(__obj.childClass.mediaThumbnailImage)).attr({ src: publicationMediaAttributes.mediaMobileImage, title: publicationMediaAttributes.mediaToolTipText });
                        var src = publicationMediaAttributes.mediaMobileImage;
                        mediaThumbnailImage.css({
                            'background-image': 'url("' + src + '")',
                            'background-repeat': 'no-repeat',
                            'background-size': 'contain',
                            'background-position': 'center center'
                        })
                }
                mediaOverlay.append(mediaOverlayIconWrapper);
                if (_obj.helpers.hasMediaRedirect(publicationMediaAttributes)) {
                    mediaThumbnailHolder.append(mediaThumbnailImage, mediaOverlay);
                } else {
                    mediaThumbnailHolder.append(mediaThumbnailImage);
                }

                template = main.append(mediaThumbnailHolder, mediaHolder);

                return template;
            };

            __obj.loadDefaults();

            return __obj;
        };

        _obj.mlHelpers = new mlHelpers();
        _obj.parent = parent;
        _obj.loadDefaults = function () {
            _obj.parentClass = '.ml-publication';
            _obj.childClass = {
                header: '.ml-publication-header',
                link: '.ml-publication-link',
                media: '.ml-publication-media',
                centerMedia: '.media-center'
            };
        };
        _obj.initializePublicationMedia = function (carouselInstance) {
            carouselInstance.find(_obj.parentClass).each(function (index) {
                var publicationInstance = jQuery(this);
                var publicationMediaInstance = publicationInstance.find(_obj.childClass.media);
                var publicationMediaAttributes = _obj.helpers.getMediaAttributes(publicationMediaInstance);

                if (_obj.helpers.hasMedia(publicationInstance)) {
                    if (_obj.helpers.isAudioMediaType(publicationMediaAttributes)) {
                        publicationMediaInstance.prepend(_obj.templates.getAudioDisplayByAttributes(publicationMediaAttributes));
                    } else if (_obj.helpers.isVideoMediaType(publicationMediaAttributes)) {
                        publicationMediaInstance.prepend(_obj.templates.getVideoDisplayByAttributes(publicationMediaAttributes));
                    } else if (_obj.helpers.isPhotoMediaType(publicationMediaAttributes)) {
                        if (publicationMediaAttributes.mediaHideText == "false") {
                            publicationInstance.find(_obj.childClass.header).addClass('hidden');
                            publicationInstance.find(_obj.childClass.link).addClass('hidden');

                            publicationMediaInstance.addClass(_obj.mlHelpers.removeDotNotation(_obj.childClass.centerMedia));
                        }
                        publicationMediaInstance.prepend(_obj.templates.getPhotoDisplayByAttributes(publicationMediaAttributes));
                    } else if (_obj.helpers.isLeftPhotoMediaType(publicationMediaAttributes)) {
                        //if (_obj.mlHelpers.isMobileView() && publicationMediaAttributes.mediaHideMobile == "false") {
                        //    publicationInstance.find(_obj.childClass.media).addClass('hidden');
                        //}
                        if (_obj.mlHelpers.isMobileView()) {
                            publicationInstance.attr('data-has-media', false);
                        }
                        publicationMediaInstance.prepend(_obj.templates.getLeftPhotoDisplayByAttributes(publicationMediaAttributes));
                    } else if (_obj.helpers.isRightPhotoMediaType(publicationMediaAttributes)) {
                        //if (_obj.mlHelpers.isMobileView() && publicationMediaAttributes.mediaHideMobile == "false") {
                        //    publicationInstance.find(_obj.childClass.media).addClass('hidden');
                        //}
                        if (_obj.mlHelpers.isMobileView()) {
                            publicationInstance.attr('data-has-media', false);
                        }
                        publicationMediaInstance.prepend(_obj.templates.getRightPhotoDisplayByAttributes(publicationMediaAttributes));
                    } else if (_obj.helpers.isPDFMediaType(publicationMediaAttributes)) { // [JPM]
                        if (publicationMediaAttributes.mediaHideText == "false") {
                            publicationInstance.find(_obj.childClass.header).addClass('hidden');
                            publicationInstance.find(_obj.childClass.link).addClass('hidden');

                            publicationMediaInstance.addClass(_obj.mlHelpers.removeDotNotation(_obj.childClass.centerMedia));
                        }
                        publicationMediaInstance.prepend(_obj.templates.getPDFDisplayByAttributes(publicationMediaAttributes));
                    }
                    else if (_obj.helpers.isLeftImagePDFMediaType(publicationMediaAttributes)) { // [JPM]
                        if (_obj.mlHelpers.isMobileView() && publicationMediaAttributes.mediaHideMobile == "false") {
                            publicationInstance.find(_obj.childClass.media).addClass('hidden');
                        }
                        publicationMediaInstance.prepend(_obj.templates.getLeftImagePDFDisplayByAttributes(publicationMediaAttributes));
                    } else if (_obj.helpers.isRightImagePDFMediaType(publicationMediaAttributes)) { // [JPM]
                        if (_obj.mlHelpers.isMobileView() && publicationMediaAttributes.mediaHideMobile == "false") {
                            publicationInstance.find(_obj.childClass.media).addClass('hidden');
                        }
                        publicationMediaInstance.prepend(_obj.templates.getRightImagePDFDisplayByAttributes(publicationMediaAttributes));
                    }
                }
            });
        };
        _obj.loadResponsiveDisplay = function (carouselInstance) {
            if (_obj.mlHelpers.isMobileView() || _obj.mlHelpers.isSmallMobileView()) {
                _obj.mobileDisplay(carouselInstance); //commetend out see details on function
            } else {
                //_obj.defaultDisplay(carouselInstance); //commetend out see details on function
            }
        };
        _obj.defaultDisplay = function (carouselInstance) {
            //warning: .detach() causes the MediaElement player's UI to be unresponsive / creates errors on the player
            carouselInstance.find(_obj.parentClass).each(function (index) {
                var publicationModuleInstance = jQuery(this);
                var publicationHeader = publicationModuleInstance.find(_obj.childClass.header).detach();
                var publicationLink = publicationModuleInstance.find(_obj.childClass.link).detach();
                var publicationThumbnail = publicationModuleInstance.find(_obj.childClass.media).detach();

                publicationModuleInstance.prepend(publicationThumbnail);
                publicationModuleInstance.prepend(publicationLink);
                publicationModuleInstance.prepend(publicationHeader);
            });
        };
        _obj.mobileDisplay = function (carouselInstance) {
            //warning: .detach() causes the MediaElement player's UI to be unresponsive / creates errors on the player
            carouselInstance.find(_obj.parentClass).each(function (index) {
                var publicationModuleInstance = jQuery(this);
                var publicationHeader = publicationModuleInstance.find(_obj.childClass.header).detach();
                publicationModuleInstance.prepend(publicationHeader);
                //var publicationLink = publicationModuleInstance.find(_obj.childClass.link).detach();
                //var publicationThumbnail = publicationModuleInstance.find(_obj.childClass.media).detach();
                //publicationModuleInstance.prepend(publicationLink);
                //publicationModuleInstance.prepend(publicationThumbnail);
            });
        };
        _obj.templates = new _templates(_obj);
        _obj.helpers = {};
        _obj.helpers.hasMedia = function (publicationInstance) {
            var hasMedia = publicationInstance.attr('data-has-media');

            if (hasMedia == "true") {
                return true;
            } else {
                return false;
            }
        };
        _obj.helpers.hasMediaRedirect = function (mediaAttributes) {
            if (typeof mediaAttributes.mediaRedirect !== 'undefined' && mediaAttributes.mediaRedirect.length) {
                return true;
            } else {
                return false;
            }
        };
        _obj.helpers.getMediaAttributes = function (publicationMediaInstance) {
            var attrs = {};

            attrs.mediaType = publicationMediaInstance.attr('data-media-type');
            attrs.mediaSource = publicationMediaInstance.attr('data-media-src');
            attrs.imageThumbnail = publicationMediaInstance.attr('data-image-thumbnail');
            attrs.mediaThumbnail = publicationMediaInstance.attr('data-media-thumbnail');
            attrs.mediaMobileImage = publicationMediaInstance.attr('data-media-mobile');
            attrs.mediaHideMobile = publicationMediaInstance.attr('data-media-hide-mobile');
            attrs.mediaRedirect = publicationMediaInstance.attr('data-media-redirect');
            attrs.mediaSeeMoreLabel = publicationMediaInstance.attr('data-cta-text');
            attrs.mediaSeeMoreLink = publicationMediaInstance.attr('data-cta-link');
            attrs.mediaHideText = publicationMediaInstance.attr('data-media-hide-text');
            attrs.mediaToolTipText = publicationMediaInstance.attr('data-tooltip-text');
            attrs.mediaPDFTarget = publicationMediaInstance.attr('data-pdf-target');

            return attrs;
        };
        _obj.helpers.isAudioMediaType = function (publicationMediaAttributes) {
            return (publicationMediaAttributes.mediaType == "audio");
        };
        _obj.helpers.isVideoMediaType = function (publicationMediaAttributes) {
            return (publicationMediaAttributes.mediaType == "video");
        };
        _obj.helpers.isPhotoMediaType = function (publicationMediaAttributes) {
            return (publicationMediaAttributes.mediaType == "image");
        };
        _obj.helpers.isLeftPhotoMediaType = function (publicationMediaAttributes) {
            return (publicationMediaAttributes.mediaType == "leftimage");
        };
        _obj.helpers.isRightPhotoMediaType = function (publicationMediaAttributes) {
            return (publicationMediaAttributes.mediaType == "rightimage");
        };
        _obj.helpers.isPDFMediaType = function (publicationMediaAttributes) {
            return (publicationMediaAttributes.mediaType == "pdf");
        };
        _obj.helpers.isLeftImagePDFMediaType = function (publicationMediaAttributes) {
            return (publicationMediaAttributes.mediaType == "leftImagePDF");
        };
        _obj.helpers.isRightImagePDFMediaType = function (publicationMediaAttributes) {
            return (publicationMediaAttributes.mediaType == "rightImagePDF");
        };

        _obj.loadDefaults();

        return _obj;
    };
    var _mediaModuleHanlders = function (parent) {
        var _obj = this;
        var _templates = function (parent) {
            var __obj = this;

            __obj.parent = parent;
            __obj.loadDefaults = function () {
                __obj.resources = {};
                __obj.resources.audioIcon = '/~/media/MorganLewisCareers/Carousel/icon-video.ashx';
                __obj.resources.videoIcon = '/~/media/MorganLewisCareers/Carousel/icon-video.ashx';
            },
            __obj.getAudioTypeHeading = function () {
                var main = jQuery('<p>').addClass(MLCV.Helpers.removeDotNotation(__obj.parent.childClass.mediaType));
                var icon = jQuery('<img>').attr({ src: __obj.resources.audioIcon });
                var text = jQuery('<span>').text('Audio');
                var template = main.append(icon, "&nbsp;", text);

                return template;
            };
            __obj.getVideoTypeHeading = function () {
                var main = jQuery('<p>').addClass(MLCV.Helpers.removeDotNotation(__obj.parent.childClass.mediaType));
                var icon = jQuery('<img>').attr({ src: __obj.resources.videoIcon });
                var text = jQuery('<span>').text('Video');
                var template = main.append(icon, "&nbsp;", text);

                return template;
            }

            __obj.loadDefaults();

            return __obj;
        };

        _obj.parent = parent;
        _obj.loadDefaults = function () {
            _obj.parentClass = '.stacked-media-module-main-holder';
            _obj.childClass = {
                container: '.stacked-media',
                title: '.ml-media-title',
                mediaType: '.ml-media-type',
                description: '.ml-media-description',
                thumbnailHolder: '.stacked-media.stacked-media-module-main-video-holder',
                thumbnailAudioHolder: '.stacked-media.stacked-media-module-main-audio-holder',
                thumbnailVideoHolder: '.stacked-media.stacked-media-module-main-video-holder',
                audioTypeMedia: '.audio-with-thumbnail',
                videoTypeMedia: '.video-with-thumbnail',
                iframeAudio: '.audio-iframe',
                iframeVideo: '.video-iframe'
            };
        };

        _obj.mlHelpers = new mlHelpers();
        _obj.mlLightBox = new mlLightBox();
        _obj.templates = new _templates(_obj);
        _obj.initializeMediaType = function (carouselInstance) {
            carouselInstance.find(_obj.parentClass).each(function (index) {
                var mediaModuleInstance = jQuery(this);

                if (_obj.helpers.isAudioMediaType(mediaModuleInstance)) {
                    mediaModuleInstance.prepend(_obj.templates.getAudioTypeHeading());
                } else if (_obj.helpers.isVideoMediaType(mediaModuleInstance)) {
                    mediaModuleInstance.prepend(_obj.templates.getVideoTypeHeading());
                }
            });
        };
        _obj.loadResponsiveDisplay = function (carouselInstance) {
            if (_obj.mlHelpers.isMobileView() || _obj.mlHelpers.isSmallMobileView()) {
                _obj.mobileDisplay(carouselInstance);
            } else {
                _obj.defaultDisplay(carouselInstance);
            }
        };
        _obj.defaultDisplay = function (carouselInstance) {
            carouselInstance.find(_obj.parentClass).each(function (index) {
                var mediaModuleInstance = jQuery(this);
                var mediaType = mediaModuleInstance.find(_obj.childClass.mediaType).detach();
                var mediaTitle = mediaModuleInstance.find(_obj.childClass.title).detach();
                var mediaThumbnail = mediaModuleInstance.find(_obj.childClass.container).detach();

                mediaModuleInstance.prepend(mediaThumbnail);
                mediaModuleInstance.prepend(mediaTitle);
                mediaModuleInstance.prepend(mediaType);
            });
        };
        _obj.mobileDisplay = function (carouselInstance) {
            carouselInstance.find(_obj.parentClass).each(function (index) {
                var mediaModuleInstance = jQuery(this);
                var mediaType = mediaModuleInstance.find(_obj.childClass.mediaType).detach();
                var mediaTitle = mediaModuleInstance.find(_obj.childClass.title).detach();
                var mediaThumbnail = mediaModuleInstance.find(_obj.childClass.container).detach();

                mediaModuleInstance.prepend(mediaTitle);
                mediaModuleInstance.prepend(mediaThumbnail);
                mediaModuleInstance.prepend(mediaType);
            });
        };
        _obj.helpers = {};
        _obj.helpers.isAudioMediaType = function (mediaModuleInstance) {
            var audioType = mediaModuleInstance.find(_obj.childClass.audioTypeMedia);

            if (audioType.length > 0) {
                return audioType;
            }

            return false;
        };
        _obj.helpers.isVideoMediaType = function (mediaModuleInstance) {
            var videoType = mediaModuleInstance.find(_obj.childClass.videoTypeMedia);

            if (videoType.length > 0) {
                return videoType;
            }

            return false;
        };

        _obj.loadDefaults();

        return _obj;
    };
    var _eventHandlers = function (parent) {
        var _obj = this;

        _obj.helpers = new mlHelpers();
        _obj.parent = parent;
        _obj.onResize = {};
        _obj.onResize.init = function () {
            _obj.onResize.resizeCarouselInstance();
            _obj.onResize.transformCarouselItems();

            jQuery(window).on('resize', function () {;
                var currentViewPort = {
                    width: jQuery(window).width(),
                    height: jQuery(window).height()
                };

                //mobile browser triggers the scroll event, thus double check if there was a change on the window dimensions
                if (VIEWPORT_CACHE.isSameDimension(currentViewPort.width, currentViewPort.height) === false) {
                    //update cache dimensions
                    VIEWPORT_CACHE.setWidth(currentViewPort.width);
                    VIEWPORT_CACHE.setHeight(currentViewPort.height);

                    _obj.onResize.resizeCarouselInstance();
                    _obj.onResize.transformCarouselItems(); // underlying code (.detach) causes UI issues / erorr on Medialement player

                    //pause players on orientation change
                    // for (var player in mejs.players) {
                    //      mejs.players[player].media.pause();
                    // }
                }
            });
        };
        _obj.onResize.resizeCarouselInstance = function () {
            jQuery(_obj.parent.parentClass).each(function (index) {
                var carouselInstance = jQuery(this);
                var instanceWidth = _obj.parent.helpers.getWidth(carouselInstance);
                carouselInstance.width(instanceWidth);
                _obj.onResize.resizeCarouselItems(carouselInstance, instanceWidth);
                _obj.parent.helpers.setLeftPosition(carouselInstance);
            });
        };
        _obj.onResize.resizeCarouselItems = function (carouselInstance, instanceWidth) {
            var maxIndex = 0;
            carouselInstance.find(_obj.parent.childClass.item).each(function (index) {
                var carouselItem = jQuery(this);
                carouselItem.width(instanceWidth);
                maxIndex = index;
            });
            _obj.onResize.resizeCarouselPanning(carouselInstance, instanceWidth, maxIndex);
        };
        _obj.onResize.resizeCarouselPanning = function (carouselInstance, instanceWidth, maxIndex) {
            var panningContainer = jQuery(carouselInstance.find(_obj.parent.childClass.panningContainer)[0]);
            var panWidth = (instanceWidth * (maxIndex + 1)) + instanceWidth;
            panningContainer.width(panWidth);
        };
        _obj.onResize.transformCarouselItems = function () {
            jQuery(_obj.parent.parentClass).each(function (index) {
                var carouselInstance = jQuery(this);
                _obj.parent.mediaModuleHanlders.loadResponsiveDisplay(carouselInstance);
                _obj.parent.publicationModuleHandlers.loadResponsiveDisplay(carouselInstance); //causes carousel's video UI to be unresponsive after change in orientation
            });
        };
        _obj.onClick = {};
        _obj.onClick.init = function () {
            jQuery(_self.parentClass).each(function (index) {
                var carouselInstance = jQuery(this);

                carouselInstance.find(_obj.parent.mediaModuleHanlders.parentClass).each(function (index) {
                    var mediaModuleInstance = jQuery(this);
                    var mediaModuleContainer = mediaModuleInstance.find(_obj.parent.mediaModuleHanlders.childClass.container);

                    mediaModuleContainer.click(function (e) {
                        var buttonInstance = jQuery(this);
                        _self.eventHandlers.onClick.openLightBoxModal(e, buttonInstance);
                    });
                });

                carouselInstance.find(_obj.parent.publicationModuleHandlers.parentClass).each(function (index) {
                    var publicationInstance = jQuery(this);
                    var publicationMediaContainer = publicationInstance.find(_obj.parent.publicationModuleHandlers.childClass.media);
                    var publicationMediaInstance = publicationMediaContainer.find(_obj.parent.publicationModuleHandlers.templates.parentClass);
                    var publicationMediaLink = publicationInstance.find(_obj.parent.publicationModuleHandlers.childClass.link);
                    var mediaAttributes = _obj.parent.publicationModuleHandlers.helpers.getMediaAttributes(publicationMediaContainer);

                    if (_obj.parent.publicationModuleHandlers.helpers.hasMedia(publicationInstance)) {
                        if (_obj.parent.publicationModuleHandlers.helpers.hasMediaRedirect(mediaAttributes)) {
                            publicationMediaInstance.click(function (e) {
                                e.preventDefault();
                                var buttonInstance = jQuery(this);
                                _self.eventHandlers.onClick.redirectPublication(e, buttonInstance);
                            });
                        } else {
                            if (!_obj.parent.publicationModuleHandlers.helpers.isPhotoMediaType(mediaAttributes) && !_obj.parent.publicationModuleHandlers.helpers.isLeftPhotoMediaType(mediaAttributes) && !_obj.parent.publicationModuleHandlers.helpers.isRightPhotoMediaType(mediaAttributes) && !_obj.parent.publicationModuleHandlers.helpers.isRightImagePDFMediaType(mediaAttributes) && !_obj.parent.publicationModuleHandlers.helpers.isLeftImagePDFMediaType(mediaAttributes) && !_obj.parent.publicationModuleHandlers.helpers.isPDFMediaType(mediaAttributes)) {
                                publicationMediaInstance.click(function (e) {
                                    e.preventDefault();
                                    var buttonInstance = jQuery(this);
                                    _self.eventHandlers.onClick.openLightBoxModalForPublication(e, buttonInstance);
                                });
                                /*Carousel Text Link - Pop Up Video*/
                                publicationMediaLink.click(function (e) {
                                    e.preventDefault();
                                    var linkInstance = jQuery(publicationMediaInstance);
                                    _self.eventHandlers.onClick.openLightBoxModalForPublication(e, linkInstance);
                                });
                            }
                        }
                    }
                });
            });
        };
        _obj.onClick.pageIndicator = function (element) {
            var carouselInstance = jQuery(element).closest(_obj.parent.parentClass);
            carouselInstance.data('activePageIndex', element.data('pageIndex'));
            _obj.parent.helpers.setLeftPosition(carouselInstance);
            _obj.parent.helpers.setActivePageButton(carouselInstance, element);
        };
        _obj.onClick.openLightBoxModal = function (e, element) {
            var mediaModuleInstance = element.closest(_obj.parent.mediaModuleHanlders.parentClass);
            var mediaModuleContainer = element;
            var mediaType = '';
            var mediaLink = '';
            var mediaSource = '';
            var mediaTitle = mediaModuleInstance.find(_obj.parent.mediaModuleHanlders.childClass.title).text();
            var mediaDescription = mediaModuleInstance.find(_obj.parent.mediaModuleHanlders.childClass.description).text();

            if (mediaModuleContainer[0].hasAttribute('data-video-url')) {
                mediaType = 'video';
                mediaLink = mediaModuleContainer.attr('data-video-url');
                //mediaSource = (_obj.helpers.isYoutubeUrl(mediaLink) ? 'youtube' : '');
                if (_obj.helpers.isYoutubeUrl(mediaLink)) {
                    mediaSource = 'youtube';
                } else if (_obj.helpers.isVimeoUrl(mediaLink)) {
                    mediaSource = 'vimeo';
                } else {
                    mediaSource = '';
                }
            } else if (mediaModuleContainer[0].hasAttribute('data-audio-url')) {
                mediaType = 'audio';
                mediaLink = mediaModuleContainer.attr('data-audio-url');
            }

            _obj.parent.mlLightBox.open(mediaType, mediaLink, mediaSource, mediaTitle, mediaDescription);
        };
        _obj.onClick.openLightBoxModalForPublication = function (e, element) {
            var publicationMediaInstance = element;
            var publicationInstance = publicationMediaInstance.closest(_obj.parent.publicationModuleHandlers.parentClass);
            var publicationMediaContainer = publicationMediaInstance.closest(_obj.parent.publicationModuleHandlers.childClass.media);
            var mediaType = '';
            var mediaLink = '';
            var mediaSource = '';
            var mediaTitle = publicationInstance.find(_obj.parent.publicationModuleHandlers.childClass.link).find('a').text();
            var mediaDescription = publicationInstance.find(_obj.parent.mediaModuleHanlders.childClass.description).text();
            var mediaAttributes = _obj.parent.publicationModuleHandlers.helpers.getMediaAttributes(publicationMediaContainer);
            var ctaLink = mediaAttributes.mediaSeeMoreLink;
            var ctaText = mediaAttributes.mediaSeeMoreLabel;

            mediaLink = mediaAttributes.mediaSource;
            if (mediaAttributes.mediaType == 'video') {
                mediaType = 'video';
                //mediaSource = (_obj.helpers.isYoutubeUrl(mediaLink) ? 'youtube' : '');
                if (_obj.helpers.isYoutubeUrl(mediaLink)) {
                    mediaSource = 'youtube';
                } else if (_obj.helpers.isVimeoUrl(mediaLink)) {
                    mediaSource = 'vimeo';
                } else {
                    mediaSource = '';
                }
            } else if (mediaAttributes.mediaType == 'audio') {
                mediaType = 'audio';
            }

            _obj.parent.mlLightBox.open(mediaType, mediaLink, mediaSource, mediaTitle, mediaDescription, ctaLink, ctaText);
        };
        _obj.onClick.redirectPublication = function (e, element) {
            var publicationMediaInstance = element;
            var publicationMediaContainer = publicationMediaInstance.closest(_obj.parent.publicationModuleHandlers.childClass.media);
            var mediaAttributes = _obj.parent.publicationModuleHandlers.helpers.getMediaAttributes(publicationMediaContainer);

            if (mediaAttributes.mediaType == "leftImagePDF" || mediaAttributes.mediaType == "rightImagePDF" || mediaAttributes.mediaType == "pdf") {
                window.open(mediaAttributes.mediaRedirect, mediaAttributes.mediaPDFTarget);
            } else {
                location.href = mediaAttributes.mediaRedirect;
            }
        };

        return _obj;
    };
    var _helpers = function (parent) {
        var _obj = this;

        _obj.parent = parent;
        _obj.setLeftPosition = function (carouselInstance) {
            var panningContainer = carouselInstance.find(_obj.parent.childClass.panningContainer);
            panningContainer.css({ left: _obj.parent.helpers.getLeftPosition(carouselInstance) });
        };
        _obj.setActivePageButton = function (carouselInstance, pageButton) {
            var pageButtonIcon = carouselInstance.find(_obj.parent.childClass.activePageIndicatorButton);
            if (pageButtonIcon.length > 0) {
                pageButtonIcon.removeClass(
                    MLCV.Helpers
                    .removeDotNotation(
                        _obj.parent.childClass.activePageIndicatorButton))
                        .addClass(
                            MLCV.Helpers
                            .removeDotNotation(_obj.parent.childClass.inactivePageIndicatorButton));
            }
            pageButtonIcon = pageButton.find(_obj.parent.childClass.inactivePageIndicatorButton);
            if (pageButtonIcon.length > 0) {
                pageButtonIcon.removeClass(
                    MLCV.Helpers
                    .removeDotNotation(
                        _obj.parent.childClass.inactivePageIndicatorButton))
                        .addClass(
                            MLCV.Helpers
                            .removeDotNotation(_obj.parent.childClass.activePageIndicatorButton));
            }
        };
        _obj.getLeftPosition = function (carouselInstance) {
            return (carouselInstance.width() * carouselInstance.data('activePageIndex')) * -1;
        };
        _obj.getWidth = function (carouselInstance) {
            var parentNode = jQuery(carouselInstance.parent()[0]);
            return Math.round(parentNode.width());
        };

        return _obj;
    };

    _self.publicationModuleHandlers = new _publicationModuleHandlers(_self);
    _self.mediaModuleHanlders = new _mediaModuleHanlders(_self);
    _self.mlLightBox = new mlLightBox();
    _self.eventHandlers = new _eventHandlers(_self);
    _self.helpers = new _helpers(_self);
    _self.removeDefaultMediaIframes = function () {
        jQuery(_self.parentClass).each(function (e) {
            var carouselInstance = jQuery(this);

            carouselInstance.find(_self.mediaModuleHanlders.parentClass).each(function (e) {
                var mediaModuleInstance = jQuery(this);

                mediaModuleInstance.find(_self.mediaModuleHanlders.childClass.iframeAudio).remove();
                mediaModuleInstance.find(_self.mediaModuleHanlders.childClass.iframeVideo).remove();
            });
        });
    };

    var _autoScrollInterval;
    var _carouselInstance;
    var autoScrollCarousel = function () {
        var activeI = _carouselInstance.find(_self.childClass.activePageIndicatorButton).first();
        var total = _carouselInstance.find(_self.childClass.pageIndicatorButtons).length;

        if (total > 1 && activeI && activeI.length > 0) {
            var index = activeI.closest('li').index();
            var nextIndex = index == (total - 1) ? 0 : index + 1;
            var nextButton = _carouselInstance.find(_self.childClass.pageIndicatorButtons).eq(nextIndex);
            _self.eventHandlers.onClick.pageIndicator(nextButton);
        }
    }
    var _pauseVideosInCarousel = function () {
        //mejs - global varaible created by MediaElement.js
        for (var player in mejs.players) {
            var id = "#" + mejs.players[player].id;
            //check if parent is a carousel
            if (jQuery(id).closest(".ml-carousel").length !== 0)
                mejs.players[player].media.pause();
        }
    };
    _self.autoScrollPlay = function (timeAutoScroll) {
        clearInterval(_autoScrollInterval);
        _autoScrollInterval = window.setInterval(function () { autoScrollCarousel() }, timeAutoScroll || 5000);
    };
    _self.autoScrollStop = function () {
        clearInterval(_autoScrollInterval)
    };

    _self.init = function () {
        var loadDefaults = function () {
            _self.parentClass = '.ml-carousel';
            _self.childClass = {
                item: '.ml-carousel-panning .ml-carousel-item',
                panningContainer: '.ml-carousel-panning',
                pageIndicator: '.ml-carousel-page-indicator',
                pageIndicatorButtons: '.ml-carousel-page-indicator>ul>li>a',
                pageIndicatorButtonContainer: '.ml-carousel-page-indicator',
                activePageIndicatorButton: '.pagination-item.pagination-item--active',
                inactivePageIndicatorButton: '.pagination-item',
                publicationMedia: '.ml-carousel-panning .ml-carousel-item .ml-publication .ml-publication-media.media-center .ml-publication-media-instance'
            };
        };
        var handleClickEvents = function () {
            _self.eventHandlers.onClick.init();
        };
        var handleScreenResize = function () {
            _self.eventHandlers.onResize.init();
        };
        var pageIndicatorButtonClick = function (element) {
            var buttonInstance = jQuery(element);
            _self.autoScrollStop();
            _pauseVideosInCarousel();
            _self.eventHandlers.onClick.pageIndicator(buttonInstance);
            _self.autoScrollPlay(); //after 5 seconds / timeAutoScroll value, start scroll carousel
        };
        var handleMultipleInstance = function () {
            jQuery(_self.parentClass).each(function (index) {
                _carouselInstance = jQuery(this);
                _carouselInstance.data('activePageIndex', 0);

                var timeAutoScroll = 5000; // 5 seconds
                _self.autoScrollPlay();
                var pageButtonContainerList = jQuery('<ul>');
                var pageButtonContainer = _carouselInstance.find(_self.childClass.pageIndicatorButtonContainer);
                _carouselInstance.find(_self.childClass.item).each(function (index) {
                    
                    var pageButton = jQuery('<li>');
                    var pageIndicatorButton = jQuery('<a>').append(jQuery('<span>').addClass(MLCV.Helpers.removeDotNotation(_self.childClass.inactivePageIndicatorButton)));

                    pageIndicatorButton.data('pageIndex', index);
                    var page = $(".ml-publication-link a");

                    if (index == _carouselInstance.data('activePageIndex')) {
                        page.eq(index).attr('tabindex', 0);
                    }

                    pageIndicatorButton.attr('tabindex', 0);
                    pageIndicatorButton.click(function (e) {
                        pageIndicatorButtonClick(this);
                    });
                    pageIndicatorButton.keypress(function (e) {
                        pageIndicatorButtonClick(this);
                        _self.autoScrollStop();
                        var page = $(".ml-publication-link a");
                        $(page).each(function (i) {
                            var tabindex = i == _carouselInstance.data('activePageIndex') ? 0 : -1;
                            $(page).eq(i).attr('tabindex', tabindex);
                        });
                    });
                    pageIndicatorButton.focus(function (e) {
                        _self.autoScrollStop();
                    });
                    pageButton.append(pageIndicatorButton);
                    pageButtonContainerList.append(pageButton);

                    if (index == 0) {
                        _self.helpers.setActivePageButton(_carouselInstance, pageIndicatorButton);
                    }
                });
                pageButtonContainer.append(pageButtonContainerList);

                _self.mediaModuleHanlders.initializeMediaType(_carouselInstance);
                _self.publicationModuleHandlers.initializePublicationMedia(_carouselInstance);
            });
        };
        var removeDefaultIframes = function () {
            _self.removeDefaultMediaIframes();
        };

        loadDefaults();
        removeDefaultIframes();
        handleMultipleInstance();
        handleScreenResize();
        handleClickEvents();
        var token = jQuery('.collapse-box .people-intro').find("p:contains('$carousel-container')");
        var carouselInstance = jQuery('.collapse-box .people-intro').find('.ml-carousel');
        if (token.length > 0) {
            if (carouselInstance.length > 0) {
                jQuery(token).replaceWith(carouselInstance);
            }
        }

        /*Carousel Dot Pagination and Size*/
        jQuery(_self.parentClass).each(function (index) {
            $moduleselectionItem = jQuery(this).find(_self.childClass.item);
            $pageholder = jQuery(this).find(_self.childClass.pageIndicator);

            if ($moduleselectionItem.length <= 1) {
                $pageholder.hide();
            }
            var maxHeight = 0;
            $moduleselectionItem.each(function (index) {
                if (jQuery(this).height() > maxHeight) {
                    maxHeight = jQuery(this).height();
                }
            });
            if (isMobile.any) {
                var pubMediaHeight = jQuery(_self.childClass.publicationMedia).height();
                $moduleselectionItem.each(function (index) {
                    if (jQuery(_self.childClass.publicationMedia).height() > maxHeight) {
                        maxHeight = jQuery(_self.childClass.publicationMedia).height();
                    }
                });
            }
            $moduleselectionItem.css({ 'height': maxHeight });
        });
    };

    return _self;
};
var mlVerticalPlaylist = function () {
    var _self = this;
    var _mediaModuleHanlders = function (parent) {
        var _obj = this;

        _obj.parent = parent;
        _obj.helpers = new mlHelpers();
        _obj.nativeMediaHandler = new mlNativeMediaHandler();
        _obj.loadDefaults = function () {
            _obj.parentClass = '.stacked-media-module-main-holder';
            _obj.childClass = {
                container: '.stacked-media',
                title: '.ml-media-title',
                description: '.ml-media-description',
                thumbnailHolder: '.stacked-media.stacked-media-module-main-video-holder',
                thumbnailAudioHolder: '.stacked-media.stacked-media-module-main-audio-holder',
                thumbnailVideoHolder: '.stacked-media.stacked-media-module-main-video-holder',
                thumbnailAudioPlayicon: '.stacked-media-module-overlay-wrapper img',
                thumbnailAudioMedia: '.audio-with-thumbnail',
                thumbnailVideoMedia: '.video-with-thumbnail',
                oldIframeAudio: '.audio-iframe.hidden',
                oldIframeVideo: '.video-iframe.hidden',
                iframeAudio: '.audio-iframe',
                iframeVideo: '.video-iframe'
            };
        };
        _obj.getMediaAttributes = function (mediaModuleInstance) {
            var mediaAttributes = {};

            if (_obj.isAudioMedia(mediaModuleInstance)) {
                mediaAttributes = _obj.getMediaAudioAttributes(mediaModuleInstance);
            } else if (_obj.isVideoMedia(mediaModuleInstance)) {
                mediaAttributes = _obj.getMediaVideoAttributes(mediaModuleInstance);
            }

            return mediaAttributes;
        };
        _obj.getMediaAudioAttributes = function (mediaModuleInstance) {
            var mediaAttributes = {};
            mediaAttributes.mediaType = 'audio';
            mediaAttributes.mediaLink = mediaModuleInstance.find(_obj.childClass.container).attr('data-audio-url');
            mediaAttributes.mediaSource = mediaModuleInstance.find(_obj.childClass.container).attr('data-source-type');
            mediaAttributes.mediaTitle = mediaModuleInstance.find(_obj.childClass.container).attr('data-media-title');
            mediaAttributes.mediaDescription = mediaModuleInstance.find(_obj.childClass.container).attr('data-media-desc');
            mediaAttributes.ctaLink = mediaModuleInstance.find(_obj.childClass.container).attr('data-cta-link');
            mediaAttributes.ctaText = mediaModuleInstance.find(_obj.childClass.container).attr('data-cta-text');
            mediaAttributes.mediaIndex = mediaModuleInstance.find(_obj.childClass.container).data('itemIndex');

            return mediaAttributes;
        };
        _obj.getMediaVideoAttributes = function (mediaModuleInstance) {
            var mediaAttributes = {};

            mediaAttributes.mediaType = 'video';
            mediaAttributes.mediaLink = mediaModuleInstance.find(_obj.childClass.container).attr('data-video-url');
            mediaAttributes.mediaSource = mediaModuleInstance.find(_obj.childClass.container).attr('data-source-type');
            mediaAttributes.mediaTitle = mediaModuleInstance.find(_obj.childClass.container).attr('data-media-title');
            mediaAttributes.mediaDescription = mediaModuleInstance.find(_obj.childClass.container).attr('data-media-desc');
            mediaAttributes.ctaLink = mediaModuleInstance.find(_obj.childClass.container).attr('data-cta-link');
            mediaAttributes.ctaText = mediaModuleInstance.find(_obj.childClass.container).attr('data-cta-text');
            mediaAttributes.mediaIndex = mediaModuleInstance.find(_obj.childClass.container).data('itemIndex');

            return mediaAttributes;
        };
        _obj.getMediaContainer = function (mediaModuleInstance) {
            var mediaContainer = {};

            if (_obj.isAudioMedia(mediaModuleInstance)) {
                mediaContainer = mediaModuleInstance.find(_obj.childClass.thumbnailAudioHolder);
            } else if (_obj.isVideoMedia(mediaModuleInstance)) {
                mediaContainer = mediaModuleInstance.find(_obj.childClass.thumbnailVideoHolder);
            }

            return mediaContainer;
        };
        _obj.isAudioMedia = function (mediaModuleInstance) {
            var isAudio = mediaModuleInstance.find(_obj.childClass.thumbnailAudioHolder);

            return (isAudio.length > 0 ? true : false);
        };
        _obj.isVideoMedia = function (mediaModuleInstance) {
            var isVideo = mediaModuleInstance.find(_obj.childClass.thumbnailVideoHolder);

            return (isVideo.length > 0 ? true : false);
        };
        _obj.createIframeElementReplacement = function (mediaModuleInstance) {
            var elementReplacement = {};

            if (_obj.isAudioMedia(mediaModuleInstance)) {
                elementReplacement = _obj.createIframeElementReplacementForAudio(mediaModuleInstance);
            } else if (_obj.isVideoMedia(mediaModuleInstance)) {
                elementReplacement = _obj.createIframeElementReplacementForVideo(mediaModuleInstance);
            }

            return elementReplacement;
        };
        _obj.createIframeElementReplacementForAudio = function () {
            var elementReplacement = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.childClass.iframeAudio));

            return elementReplacement;
        };
        _obj.createIframeElementReplacementForVideo = function () {
            var elementReplacement = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.childClass.iframeVideo));

            return elementReplacement;
        };

        _obj.loadDefaults();

        return _obj;
    };
    var _eventHandlers = function (parent) {
        var _obj = this;

        _obj.parent = parent;
        _obj.onResize = {};

        _obj.onResize.toggleInlinePlayers = function () {
        };
        _obj.onClick = {};
        _obj.onClick.init = function () {
            jQuery(_obj.parent.parentClass).each(function (index) {
                var playlistInstance = jQuery(this);

                playlistInstance.find(_obj.parent.childClass.item).each(function (index) {
                    var playListItem = jQuery(this);
                    var mediaModuleContainer = playListItem.find(_obj.parent.mediaModuleHanlders.childClass.container);

                    mediaModuleContainer.click(function (e) {
                        var buttonInstance = jQuery(this);
                        _obj.onClick.openLightBoxModal(e, buttonInstance);

                        return false;
                    });
                });
            });
        };
        _obj.onClick.openLightBoxModal = function (e, element) {
            var verticalPlaylistInstance = element.closest(_obj.parent.parentClass);
            var mediaModuleInstance = element.closest(_obj.parent.mediaModuleHanlders.parentClass);
            var mediaAttributes = _obj.parent.mediaModuleHanlders.getMediaAttributes(mediaModuleInstance);
            var playlistItems = _obj.parent.getMediaAttributeList(verticalPlaylistInstance);
            var playlistId = verticalPlaylistInstance.data('playlist-guid');
            var totalMediaItems = verticalPlaylistInstance.data('total-media');

            _self.mlLightBox.openWithCarousel(mediaAttributes, playlistItems, playlistId, totalMediaItems);

            return false;
        };
        _obj.onClick.loadMoreButton = function (e, element) {
            var playlistInstance = element.closest(_obj.parent.parentClass);
            var preloader = playlistInstance.find(_obj.parent.childClass.playListFooterPreloader);
            _self.loadAjax(_obj.parent, element,
                function () { // on init
                    element.hide();
                    preloader.removeClass(_obj.parent.childClass.hiddenPlayListFooterPreloader);
                },
                function (data, nextIndex) { // on success
                    setTimeout(function () {
                        var parentContainer = playlistInstance.find(_obj.parent.childClass.playlistContainer);
                        parentContainer.find('.ml-playlist-videos').append(data); // append new HTML media list  _obj.parent.childClass.row.playlistContainer
                        playlistInstance.attr(_obj.parent.otherAttribute.currentIndex, nextIndex); // update the new current index

                        element.show();
                        preloader.addClass(_obj.parent.childClass.hiddenPlayListFooterPreloader);

                        _self.refresh();
                        _self.refreshShowMoreButton(_obj.parent, playlistInstance); // check if show-more button should now be hidden
                    }, 500);
                },
                function () { // on fail
                    element.hide();
                    preloader.addClass(_obj.parent.childClass.hiddenPlayListFooterPreloader);
                }
            );
        };

        return _obj;
    };

    _self.helpers = new mlHelpers();
    _self.mlLightBox = new mlLightBox();
    _self.nativeMediaHandler = new mlNativeMediaHandler();
    _self.removeDefaultMediaIframes = function () {
        jQuery(_self.parentClass).each(function (e) {
            var verticalPlaylistInstance = jQuery(this);

            verticalPlaylistInstance.find(_self.mediaModuleHanlders.parentClass).each(function (e) {
                var mediaModuleInstance = jQuery(this);

                if (mediaModuleInstance.hasClass('ml-transformed')) {
                    return;
                }

                mediaModuleInstance.find(_self.mediaModuleHanlders.childClass.iframeAudio).remove();
                mediaModuleInstance.find(_self.mediaModuleHanlders.childClass.iframeVideo).remove();
            });
        });
    };
    _self.createNewMediaIframes = function () {
        jQuery(_self.parentClass).each(function (e) {
            var verticalPlaylistInstance = jQuery(this);

            verticalPlaylistInstance.find(_self.mediaModuleHanlders.parentClass).each(function (e) {
                var mediaModuleInstance = jQuery(this);

                if (mediaModuleInstance.hasClass('ml-transformed')) {
                    return;
                }

                var mediaAttributes = _self.mediaModuleHanlders.getMediaAttributes(mediaModuleInstance);
                var iframeReplacement = _self.mediaModuleHanlders.createIframeElementReplacement(mediaModuleInstance);
                var mediaContainer = _self.mediaModuleHanlders.getMediaContainer(mediaModuleInstance);

                if (_self.helpers.isMobileView()) {
                    mediaModuleInstance.find(_self.mediaModuleHanlders.childClass.thumbnailAudioPlayicon).addClass('hidden');
                    mediaModuleInstance.find(_self.mediaModuleHanlders.childClass.thumbnailVideoMedia).addClass('hidden');

                    mediaContainer.append(iframeReplacement);
                    mediaContainer = mediaContainer.find(iframeReplacement);
                    _self.nativeMediaHandler.transform(mediaAttributes.mediaType, mediaAttributes.mediaLink, '', mediaContainer);

                    var helper = new mlHelpers();
                } else {
                    mediaModuleInstance.find(_self.mediaModuleHanlders.childClass.thumbnailAudioMedia).removeClass('hidden');
                    mediaModuleInstance.find(_self.mediaModuleHanlders.childClass.thumbnailVideoMedia).removeClass('hidden');
                }

                mediaModuleInstance.addClass('ml-transformed');
            });
        });
    };
    _self.mediaModuleHanlders = new _mediaModuleHanlders(_self);
    _self.eventHandlers = new _eventHandlers(_self);
    _self.getMediaAttributeList = function (verticalPlaylistInstance) {
        var mediaAttributeList = [];
        var mediaItems = verticalPlaylistInstance.find(_self.mediaModuleHanlders.parentClass);

        mediaItems.each(function () {
            var mediaBoxContianerInstance = jQuery(this);
            var mediaAttribute = _self.mediaModuleHanlders.getMediaAttributes(mediaBoxContianerInstance);

            mediaAttributeList.push(mediaAttribute);
        });

        return mediaAttributeList;
    };
    _self.setMediaIndex = function () {
        jQuery(_self.parentClass).each(function (e) {
            var verticalPlaylistInstance = jQuery(this);
            var mediaItems = verticalPlaylistInstance.find(_self.mediaModuleHanlders.childClass.container);

            mediaItems.each(function (index) {
                jQuery(this).data('itemIndex', index);
            });
        });
    };
    _self.refresh = function () {
        var handleOnClickEvents = function () {
            _self.eventHandlers.onClick.init();
        };
        var removeDefaultIframes = function () {
            _self.removeDefaultMediaIframes();
        };
        var createNewMediaIframes = function () {
            _self.createNewMediaIframes();
        };
        var setMediaIndex = function () {
            _self.setMediaIndex();
        };

        removeDefaultIframes();
        createNewMediaIframes();
        handleOnClickEvents();
        setMediaIndex();
    };
    _self.init = function () {
        var loadDefaults = function () {
            _self.parentClass = '.ml-playlist.vertical';
            _self.parentContainerClass = '.top-level-cont, .collapse-box';
            _self.childClass = {
                item: '.ml-playlist-videos-container .ml-playlist-item',
                playlistContainer: '.ml-playlist-videos-container',
                playListFooterContainer: '.ml-playlist-footer-container',
                playListFooterPreloader: '.ml-playlist-footer-container .ml-playlist-preloader',
                playListFooterLoadMoreButton: '.ml-playlist-footer-container .ml-playlist-load-more',
                hiddenPlayListFooterPreloader: 'hidden'
            };
            _self.dataAttribute = {
                mediaList: 'media-list',
                displayNum: 'display-num',
                maxNum: 'max-num',
                playlistGuid: 'playlist-guid'
            };
            _self.otherAttribute = {
                currentIndex: 'data-current-index'
            };
            _self.ajaxUrl = '/api/custom/careers/playlist/partialplaylist';
        };
        var handleMultipleInstance = function () {
            jQuery(_self.parentClass).each(function (index) {
                _self.refreshShowMoreButton(_self, this);
                var loadMoreButton = jQuery(this).find(_self.childClass.playListFooterLoadMoreButton);

                if (loadMoreButton.hasClass('has-event'))
                    return;

                loadMoreButton.click(function (e) {
                    var loadMoreButtonInstance = jQuery(this);
                    _self.eventHandlers.onClick.loadMoreButton(e, loadMoreButtonInstance);
                    return false;
                });

                loadMoreButton.addClass('has-event');
            });
        };

        loadDefaults();
        handleMultipleInstance();
        _self.refresh();
    };
    _self.loadAjax = function (_obj, elm, callbackInit, callbackOnSuccess, callbackOnFail) {
        callbackInit();
        var $playlist = jQuery(elm).closest(_obj.parentClass);
        if ($playlist && $playlist.length > 0) {
            var mediaList = $playlist.data(_obj.dataAttribute.mediaList);
            var displayNum = $playlist.data(_obj.dataAttribute.displayNum);
            var maxNum = parseInt($playlist.data(_obj.dataAttribute.maxNum));
            var nextIndex = parseInt($playlist.attr(_obj.otherAttribute.currentIndex)) + 1;
            var playlistGuid = $playlist.data(_obj.dataAttribute.playlistGuid);

            jQuery.ajax({
                url: _obj.ajaxUrl,
                data: {
                    mediaList: mediaList,
                    displayNum: displayNum,
                    maxNum: maxNum,
                    currentIndex: nextIndex,
                    playlistGuid: playlistGuid
                },
                type: "GET",
                success: function (data) {
                    if (data && data.trim().length > 0) {
                        callbackOnSuccess(data, nextIndex);
                    }
                    else {
                        callbackOnFail();
                    }
                },
                error: function (msg) {
                    callbackOnFail();
                }
            });
        }
    };
    _self.refreshShowMoreButton = function (_obj, elm) {
        var $playlist = jQuery(elm);
        var total = parseInt($playlist.data(_obj.dataAttribute.maxNum));
        var currentIndex = parseInt($playlist.attr(_obj.otherAttribute.currentIndex));
        var displayNum = $playlist.data(_obj.dataAttribute.displayNum);

        if (total > (currentIndex + 1) * displayNum) { // display 'show more' button
            $playlist.find(_obj.childClass.playListFooterContainer).removeClass(_obj.childClass.hiddenPlayListFooterPreloader);
            $playlist.closest(_obj.parentContainerClass).css('border-bottom', 'none');
        }
        else { // hide 'show more' button
            $playlist.find(_obj.childClass.playListFooterContainer).addClass(_obj.childClass.hiddenPlayListFooterPreloader);
            $playlist.closest(_obj.parentContainerClass).css('border-bottom', '8px solid #231f20');
        }
    };

    return _self;
};
var mlHorizontalPlaylist = function () {
    var _self = this;
    var _videoBlockHandler = function (parent) {
        var _obj = this;
        var _helpers = function (parent) {
            var __obj = this;

            __obj.parent = parent;
            __obj.getShowOnlyClass = function (displayNum) {
                var showOnlyClass = __obj.parent.parentClass + ":lt(" + displayNum + ")";

                return showOnlyClass;
            };

            return __obj;
        };

        _obj.parent = parent;
        _obj.helpers = new _helpers(_obj);
        _obj.loadDefaults = function () {
            _obj.parentClass = '.ml-media-block';
            _obj.childClass = {
                title: '.ml-video-title',
                container: {
                    main: '.ml-open-media',
                    thumbnail: '.ml-video-thumbnail',
                    thumbnailImage: '.thumb',
                    overlay: '.ml-video-thumb-overlay',
                    overlayIcon: '.playIcon',
                    mobileDisplay: '.ml-video-mobile',
                    mobileVideoDisplay: '.ml-video-mobile',
                    mobileAudioDisplay: '.ml-audio-mobile',
                    mobilePhotoDisplay: '.ml-photo-mobile',
                    nativeMediaBlock: '.ml-native-block'
                },
                alumni: {
                    container: '.c-alumni__playlist-container',
                    thumbnail: '.c-alumni__thumbnail',
                    activeThumbnail: '.c-active-thumbnail',
                    navigationIcons: '.ml-playlists .ml-playlist.spotlight .ml-playlist-videos-container.c-alumni__playlist-container .ml-playlist-nav',
                    playlistContainer: '.ml-playlist-videos-container.c-alumni__playlist-container',
                    prevCircle: '.circle-prev',
                    prevIcon: '.icon.icon-prev',
                    nextCircle:'.circle-next',
                    nextIcon:'.icon.icon-next',
                    cssClass: {
                        prevCircle: 'circle-prev',
                        nextCircle: 'circle-next'
                    },
                },
            };
        };
        _obj.getMediaAttributes = function (mediaBoxContianerInstance) {
            var mediaAttributes = {};
            mediaAttributes.mediaType = mediaBoxContianerInstance.data('mediatype');
            mediaAttributes.mediaLink = mediaBoxContianerInstance.data('medialink');
            mediaAttributes.mediaSource = mediaBoxContianerInstance.data('sourcetype');
            mediaAttributes.mediaTitle = mediaBoxContianerInstance.data('mediatitle');
            mediaAttributes.mediaDescription = mediaBoxContianerInstance.data('mediadescription');
            mediaAttributes.ctaLink = mediaBoxContianerInstance.data('ctalink');
            mediaAttributes.ctaText = mediaBoxContianerInstance.data('ctatext');
            mediaAttributes.mediaIndex = mediaBoxContianerInstance.data('itemIndex');

            return mediaAttributes;
        };

        _obj.loadDefaults();

        return _obj;
    };
    var _eventHandlers = function (parent) {
        var _obj = this;
        var _scrollAnimationHandler = function (parent) {
            var __obj = this;

            __obj.parent = parent;
            __obj.scrollAnimation = function (horizontalPlaylistInstance) {
                var listContainerInstance = horizontalPlaylistInstance.find(_obj.parent.childClass.row.listContainer);
                __obj.handlePrevButtonDisplay(horizontalPlaylistInstance, listContainerInstance);
                __obj.handleNextButtonDisplay(horizontalPlaylistInstance, listContainerInstance);
            };
            __obj.handlePrevButtonDisplay = function (horizontalPlaylistInstance, listContainerInstance) {
                var scrollLeft = listContainerInstance.scrollLeft();
                var prevNavButtonInstance = horizontalPlaylistInstance.find(_obj.parent.childClass.row.prevNav);
                var prevNavIcon = horizontalPlaylistInstance.find(_obj.parent.childClass.row.prevNavIcon);

                if (_self.helpers.isMobileView() || _self.helpers.isSmallMobileView()) {
                    prevNavButtonInstance.addClass('hidden');

                    //[LDM] Alumni Spotlight previous icon navigation controls
                    if ($(_self.videoBlockHandler.childClass.alumni.container).length > 0) {
        

                        var prevCircle = $(_self.videoBlockHandler.childClass.alumni.playlistContainer).find(_self.videoBlockHandler.childClass.alumni.prevCircle).hasClass(_self.videoBlockHandler.childClass.alumni.cssClass.prevCircle);
                        if (prevCircle != true) {
                            $(_self.videoBlockHandler.childClass.alumni.playlistContainer).find(_self.videoBlockHandler.childClass.alumni.prevIcon).append("<span class='circle-prev'></span>");
                }
                        prevNavButtonInstance.removeClass('hidden');
                        jQuery(_self.videoBlockHandler.childClass.alumni.navigationIcons).css('display','inline');
                        if (jQuery(_self.videoBlockHandler.childClass.alumni.thumbnail).find(_self.videoBlockHandler.childClass.alumni.activeThumbnail).parent().prev().length == 0) {
                            prevNavButtonInstance.addClass('hidden');
                        }             
                    }
                }
                else {
                    if (scrollLeft <= 1) {
                        prevNavButtonInstance.css({'display': 'none'});
                        prevNavIcon.css({'visibility':'hidden'});
                    }
                    else {
                        prevNavButtonInstance.css({'display': 'inline'});
                        prevNavIcon.css({'visibility':'visible'});
                    }
                }
            };
            __obj.handleNextButtonDisplay = function (horizontalPlaylistInstance, listContainerInstance) {
                if (listContainerInstance.length < 1) {
                    return false;
                }

                var width = listContainerInstance.outerWidth()
                var scrollLeft = listContainerInstance.scrollLeft();
                var scrollWidth = listContainerInstance[0].scrollWidth;
                var nextNavButtonInstance = horizontalPlaylistInstance.find(_obj.parent.childClass.row.nextNav);

                var nextIconInstance = horizontalPlaylistInstance.find(_obj.parent.childClass.row.nextNavIcon);
                var viewAllIconInstance = horizontalPlaylistInstance.find(_obj.parent.childClass.row.viewAllIcon);

                if (_self.helpers.isMobileView() || _self.helpers.isSmallMobileView()) {
                    nextIconInstance.addClass('hidden');
                    viewAllIconInstance.addClass('hidden');


                    //[LDM] Alumni Spotlight previous icon navigation controls
                    if (jQuery(_self.videoBlockHandler.childClass.alumni.container).length > 0) {
                        var nextCircle = jQuery(_self.videoBlockHandler.childClass.alumni.playlistContainer).find(_self.videoBlockHandler.childClass.alumni.nextCircle).hasClass(_self.videoBlockHandler.childClass.alumni.cssClass.nextCircle);
                        if (nextCircle != true) {
                            jQuery(_self.videoBlockHandler.childClass.alumni.playlistContainer).find(_self.videoBlockHandler.childClass.alumni.nextIcon).append("<span class='circle-next'></span>");
                }

                        nextIconInstance.removeClass('hidden');

                        if (jQuery(_self.videoBlockHandler.childClass.alumni.thumbnail).find(_self.videoBlockHandler.childClass.alumni.activeThumbnail).parent().next().length == 0) {
                            nextIconInstance.addClass('hidden');
                            if (jQuery(_self.videoBlockHandler.childClass.alumni.thumbnail).hasClass('hidden')) {
                                jQuery(_self.videoBlockHandler.childClass.alumni.thumbnail).removeClass('hidden');
                            }
                        }
                        
                    }
                    
                }
                else {
                    if (viewAllIconInstance.length) {
                        nextIconInstance.removeClass('hidden');
                        viewAllIconInstance.addClass('hidden');
                    }

                    if (((scrollWidth - width) - 1) <= scrollLeft) {
                        if (viewAllIconInstance.length) {
                            nextIconInstance.addClass('hidden');
                            viewAllIconInstance.removeClass('hidden');
                        }
                        else {
                            nextIconInstance.css({'visibility':'hidden'});
                        }
                    }
                    else {
                        nextIconInstance.css({'visibility':'visible'});
                    }
                }
            };

            return __obj;
        };

        _obj.parent = parent;
        _obj.helpers = new mlHelpers();
        _obj.onResize = {};
        _obj.onResize.init = function () {
            jQuery(window).on('resize', function () {
                _obj.onResize.toggleInlinePlayers();
            });
        };
        _obj.onResize.toggleInlinePlayers = function () {
            jQuery(_obj.parent.childClass.rowClass).each(function (index) {
                var horizontalPlaylistInstance = jQuery(this);
                _obj.parent.toggleMobileElements(horizontalPlaylistInstance);
                _obj.scrollAnimationHandler.scrollAnimation(horizontalPlaylistInstance);
            });
        };
        _obj.onClick = {};
        _obj.onClick.openLightBoxModal = function (e, element) {
            var mediaBoxContianerInstance = element;
            var horizontalPlaylistInstance = mediaBoxContianerInstance.closest(_obj.parent.childClass.rowClass);
            var mediaAttributes = _obj.parent.videoBlockHandler.getMediaAttributes(mediaBoxContianerInstance);
            var playlistItems = _obj.parent.getMediaAttributeList(horizontalPlaylistInstance);
            var playlistId = horizontalPlaylistInstance.data(_obj.parent.dataAttribute.playlistGuid);
            var totalMediaItems = horizontalPlaylistInstance.data(_obj.parent.dataAttribute.totalMedia);

            _obj.parent.mlLightBox.openWithCarousel(mediaAttributes, playlistItems, playlistId, totalMediaItems);
        };
        _obj.onClick.collapsePlaylist = function (e, element) {
            var horizontalPlaylistInstance = element.closest(_obj.parent.childClass.rowClass);
            var horizontalPlaylistContainerInstance = horizontalPlaylistInstance.find(_obj.parent.childClass.row.main);
            var horizontalPlaylistTitle = horizontalPlaylistInstance.find(_obj.parent.childClass.title.main);
            var horizontalPlaylistIconContainer = horizontalPlaylistTitle.find(_obj.parent.childClass.title.iconContainer);

            if (horizontalPlaylistContainerInstance.hasClass('open')) {
                jQuery(_obj.parent.childClass.rowClass).each(function () {
                    var playlistInstance = jQuery(this);
                    var playlistTitle = playlistInstance.find(_obj.parent.childClass.title.main);
                    var playlistIconContainer = playlistTitle.find(_obj.parent.childClass.title.iconContainer);

                    playlistInstance.children(_obj.parent.childClass.row.main).removeClass('open');
                    playlistIconContainer
                        .addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.title.iconClosed));
                    playlistIconContainer
                        .removeClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.title.iconOpened));
                });

                horizontalPlaylistIconContainer
                    .addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.title.iconClosed));
                horizontalPlaylistIconContainer
                    .removeClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.title.iconOpened));
                horizontalPlaylistContainerInstance.removeClass('open');
            }
            else {
                jQuery(_obj.parent.childClass.rowClass).each(function () {
                    var playlistInstance = jQuery(this);
                    var playlistTitle = playlistInstance.find(_obj.parent.childClass.title.main);
                    var playlistIconContainer = playlistTitle.find(_obj.parent.childClass.title.iconContainer);

                    playlistInstance.children(_obj.parent.childClass.row.main).removeClass('open');
                    playlistIconContainer
                        .addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.title.iconClosed));
                    playlistIconContainer
                        .removeClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.title.iconOpened));
                });

                horizontalPlaylistIconContainer
                    .addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.title.iconOpened));
                horizontalPlaylistIconContainer
                    .removeClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.title.iconClosed));
                horizontalPlaylistContainerInstance.addClass('open');
            }
        };
        _obj.onClick.navigateRight = function (e, element) {
            var horizontalPlaylistInstance = element.closest(_obj.parent.childClass.rowClass);
            var listContainerInstance = horizontalPlaylistInstance.find(_obj.parent.childClass.row.listContainer);
            var width = listContainerInstance.width();
            var currentScrollPosition = listContainerInstance.scrollLeft();
            var pixelToScroll = currentScrollPosition + width;

            listContainerInstance.animate({ scrollLeft: pixelToScroll }, 250, 'linear', function () {
                _obj.scrollAnimationHandler.scrollAnimation(horizontalPlaylistInstance);
            });
        };
        _obj.onClick.navigateLeft = function (e, element) {
            var horizontalPlaylistInstance = element.closest(_obj.parent.childClass.rowClass);
            var listContainerInstance = horizontalPlaylistInstance.find(_obj.parent.childClass.row.listContainer);
            var width = listContainerInstance.width();
            var currentScrollPosition = listContainerInstance.scrollLeft();
            var pixelToScroll = currentScrollPosition - width;

            listContainerInstance.animate({ scrollLeft: pixelToScroll }, 250, 'linear', function () {
                _obj.scrollAnimationHandler.scrollAnimation(horizontalPlaylistInstance);
            });
        };
        _obj.onClick.init = function (horizontalPlaylistInstance) {
            var prevNavButtonInstance = horizontalPlaylistInstance.find(_obj.parent.childClass.row.prevNav);
            var nextNavButtonInstance = horizontalPlaylistInstance.find(_obj.parent.childClass.row.nextNav);
            var accordionButtonInstance = horizontalPlaylistInstance.find(_obj.parent.childClass.title.accordionTrigger);
            var accordionTitleInstance = horizontalPlaylistInstance.find(_obj.parent.childClass.title.accordionTitle);
            var mediaBlockInstance = horizontalPlaylistInstance.find(_obj.parent.videoBlockHandler.childClass.container.main);

            _obj.scrollAnimationHandler.scrollAnimation(horizontalPlaylistInstance);

            prevNavButtonInstance.click(function (e) {
                var buttonInstance = jQuery(this);
                _obj.onClick.navigateLeft(e, buttonInstance);
            });
            nextNavButtonInstance.click(function (e) {
                var buttonInstance = jQuery(this);
                _obj.onClick.navigateRight(e, buttonInstance);
            });
            accordionButtonInstance.click(function (e) {
                e.stopImmediatePropagation();
                var buttonInstance = jQuery(this);
                _obj.onClick.collapsePlaylist(e, buttonInstance);
            });
            accordionTitleInstance.click(function (e) {
                e.stopImmediatePropagation();
                var buttonInstance = jQuery(this);
                _obj.onClick.collapsePlaylist(e, buttonInstance);
            });
            mediaBlockInstance.click(function (e) {
                var buttonInstance = jQuery(this);
                _obj.onClick.openLightBoxModal(e, buttonInstance);
            });
        };
        _obj.onClickLoadMore = {};
        _obj.onClickLoadMore.init = function (element) {
            var $element = jQuery(element);
            var collection = $element.closest(_obj.parent.parentClass);
            var preloader = collection.find(_obj.parent.childClass.footer.playListFooterPreloader);

            _self.loadAjax(_obj.parent, $element,
                function () { // on init
                    $element.hide();
                    preloader.removeClass(_obj.parent.childClass.footer.hiddenPlayListFooterPreloader);
                },
                function (data, nextIndex) { // on success
                    collection.find(_obj.parent.childClass.setClass).append(data); // append new HTML media list
                    collection.attr(_obj.parent.otherAttribute.currentIndex, nextIndex); // update the new current index
                    setTimeout(function () {
                        $element.show();
                        preloader.addClass(_obj.parent.childClass.footer.hiddenPlayListFooterPreloader);

                        _self.refresh();
                        _self.refreshShowMoreButton(_obj.parent, collection); // check if show-more button should now be hidden
                    }, 500);
                },
                function () { // on fail
                    $element.hide();
                    preloader.addClass(_obj.parent.childClass.footer.hiddenPlayListFooterPreloader);
                }
            );
        };
        _obj.scrollAnimationHandler = new _scrollAnimationHandler(_obj);

        return _obj;
    };

    _self.helpers = new mlHelpers();
    _self.mlLightBox = new mlLightBox();
    _self.eventHandlers = new _eventHandlers(_self);
    _self.videoBlockHandler = new _videoBlockHandler(_self);
    _self.mediaElementPluginHandler = new mlMediaElementPluginHandler(_self);
    _self.nativeMediaHandler = new mlNativeMediaHandler();
    _self.removeMobileMedia = function (horizontalPlaylistInstance) {
        if (!_self.helpers.isMobileView()) {
            horizontalPlaylistInstance.find(_self.videoBlockHandler.parentClass).each(function () {
                var mediaBlockInstance = jQuery(this);
                var mediaBlockCotnainerInstance = mediaBlockInstance.find(_self.videoBlockHandler.childClass.container.main);
                var mediaType = mediaBlockCotnainerInstance.data('mediatype');
                var mediaContainer = mediaBlockCotnainerInstance.find(_self.videoBlockHandler.childClass.container.mobileDisplay);

                if (mediaType == 'video') {
                    mediaContainer = mediaBlockCotnainerInstance.find(_self.videoBlockHandler.childClass.container.mobileVideoDisplay);
                } else if (mediaType == 'audio') {
                    mediaContainer = mediaBlockCotnainerInstance.find(_self.videoBlockHandler.childClass.container.mobileAudioDisplay);
                } else if (mediaType == 'photo') {
                    mediaContainer = mediaBlockCotnainerInstance.find(_self.videoBlockHandler.childClass.container.mobilePhotoDisplay);
                }

                mediaContainer.empty();
            });
        }
    };
    _self.renderMobileMedia = function (horizontalPlaylistInstance) {
        if (_self.helpers.isMobileView()) {
            horizontalPlaylistInstance.find(_self.videoBlockHandler.parentClass).each(function () {
                var mediaBlockInstance = jQuery(this);
                var cssAlreadyTransformed = _self.helpers.removeDotNotation(_self.videoBlockHandler.childClass.container.nativeMediaBlock).trim();

                if (mediaBlockInstance.hasClass(cssAlreadyTransformed)) {
                    // check first if block already has transformed native media
                    return;
                }

                var mediaBlockCotnainerInstance = mediaBlockInstance.find(_self.videoBlockHandler.childClass.container.main);
                var mediaType = mediaBlockCotnainerInstance.data('mediatype');
                var mediaLink = mediaBlockCotnainerInstance.data('medialink');
                var mediaSource = mediaBlockCotnainerInstance.data('sourcetype');
                var mediaContainer = mediaBlockCotnainerInstance.find(_self.videoBlockHandler.childClass.container.mobileDisplay);

                if (mediaType == 'video') {
                    mediaContainer = mediaBlockCotnainerInstance.find(_self.videoBlockHandler.childClass.container.mobileVideoDisplay);
                } else if (mediaType == 'audio') {
                    mediaContainer = mediaBlockCotnainerInstance.find(_self.videoBlockHandler.childClass.container.mobileAudioDisplay);
                } else if (mediaType == 'photo') {
                    mediaContainer = mediaBlockCotnainerInstance.find(_self.videoBlockHandler.childClass.container.mobilePhotoDisplay);
                }

                _self.nativeMediaHandler.transform(mediaType, mediaLink, mediaSource, mediaContainer);
                mediaBlockInstance.addClass(cssAlreadyTransformed);
            });
        }
    };
    _self.toggleMobileElements = function (horizontalPlaylistInstance) {
        var showDefaultElements = function () {
            horizontalPlaylistInstance.find(_self.videoBlockHandler.childClass.container.thumbnail).removeClass('hidden');
        };
        var hideDefaultElements = function () {
            horizontalPlaylistInstance.find(_self.videoBlockHandler.childClass.container.main).each(function (i) {
                if (jQuery(this).data('mediatype') == 'audio') {
                    jQuery(this).find(_self.videoBlockHandler.childClass.container.overlay + ' img').remove();
                }
                else {
                    jQuery(this).find(_self.videoBlockHandler.childClass.container.thumbnail).addClass('hidden');
                }

            });

        };
        var showMobileElements = function () {
            horizontalPlaylistInstance.find(_self.videoBlockHandler.childClass.container.mobileVideoDisplay).removeClass('hidden');
            horizontalPlaylistInstance.find(_self.videoBlockHandler.childClass.container.mobileAudioDisplay).removeClass('hidden');
            horizontalPlaylistInstance.find(_self.videoBlockHandler.childClass.container.mobilePhotoDisplay).removeClass('hidden');
        };
        var hideMobileElements = function () {
            horizontalPlaylistInstance.find(_self.videoBlockHandler.childClass.container.mobileVideoDisplay).addClass('hidden');
            horizontalPlaylistInstance.find(_self.videoBlockHandler.childClass.container.mobileAudioDisplay).addClass('hidden');
            horizontalPlaylistInstance.find(_self.videoBlockHandler.childClass.container.mobilePhotoDisplay).addClass('hidden');
        };

        if (_self.helpers.isMobileView()) {
            hideDefaultElements();
            showMobileElements();
        } else {
            hideMobileElements();
            showDefaultElements();
        }

        if (_self.helpers.isMobileView() || _self.helpers.isSmallMobileView()) {
            _self.setNumberOfVideosShownMobile(horizontalPlaylistInstance);
        } else {
            _self.setNumberOfVideosShown(horizontalPlaylistInstance);
        }
    };
    _self.setNumberOfVideosShownMobile = function (horizontalPlaylistInstance) {
        var videoListContainerInstance = horizontalPlaylistInstance.find(_self.childClass.row.listContainer);
        var displayNum = horizontalPlaylistInstance.data('display-num');
        var showOnlyCss = _self.videoBlockHandler.helpers.getShowOnlyClass(displayNum);

        videoListContainerInstance.find(_self.videoBlockHandler.parentClass).addClass('hidden').hide();
        videoListContainerInstance.find(showOnlyCss).removeClass('hidden').show();
        horizontalPlaylistInstance.find(_self.videoBlockHandler.parentClass).css({ 'width': '100%' });
    };
    _self.setNumberOfVideosShown = function (horizontalPlaylistInstance) {
        var videoListContainerInstance = horizontalPlaylistInstance.find(_self.childClass.row.listContainer);
        var displayNum = horizontalPlaylistInstance.data('display-num');
        var showOnlyCss = _self.videoBlockHandler.helpers.getShowOnlyClass(displayNum);
        var division = 100 / parseInt(displayNum);
        division = division + "%";

        videoListContainerInstance.find(showOnlyCss).addClass('hidden').hide();
        videoListContainerInstance.find(_self.videoBlockHandler.parentClass).removeClass('hidden').show();
        horizontalPlaylistInstance.find(_self.videoBlockHandler.parentClass).css({ 'width': division });
    };
    _self.setPrevNextNavDistance = function (horizontalPlaylistInstance) {
        var firstThumbnailInstance = horizontalPlaylistInstance.find(_self.childClass.row.mediaThumbnail).first();
        var thumbnailHeight = firstThumbnailInstance.height();
        if (thumbnailHeight) {
            var top = (thumbnailHeight / 2);
            top = _self.helpers.isTabletView() ? top - 35 : top - 16;
            horizontalPlaylistInstance.find(_self.childClass.row.prevNav).removeClass('hidden');
            horizontalPlaylistInstance.find(_self.childClass.row.nextNav).removeClass('hidden');
        }
    };
    _self.getMediaAttributeList = function (horizontalPlaylistInstance) {
        var mediaAttributeList = [];
        var mediaItems = horizontalPlaylistInstance.find(_self.videoBlockHandler.childClass.container.main);

        mediaItems.each(function () {
            var mediaBoxContianerInstance = jQuery(this);
            var mediaAttribute = _self.videoBlockHandler.getMediaAttributes(mediaBoxContianerInstance);

            mediaAttributeList.push(mediaAttribute);
        });

        return mediaAttributeList;
    };
    _self.setMediaIndex = function (horizontalPlaylistInstance) {
        var mediaItems = horizontalPlaylistInstance.find(_self.videoBlockHandler.childClass.container.main);
        mediaItems.each(function (index) {
            jQuery(this).data('itemIndex', index);
        });
    };
    _self.loadDefaults = function () {
        _self.parentClass = '.ml-playlists.collection';
        _self.parentContainerClass = '.top-level-cont, .collapse-box';
        _self.childClass = {
            setClass: '.ml-playlist-set',
            rowClass: '.ml-playlist.horizontal, .ml-playlist.vertical, .ml-playlist-row',
            title: {
                main: '.ml-playlist-title',
                accordionTrigger: '.ml-accordion-trigger',
                accordionTitle: 'h3',
                iconContainer: '.stack.icon.icon-caret-down .accordion-icon',
                iconOpened: '.rotate-180',
                iconClosed: '.rotate-90'
            },
            row: {
                main: '.ml-playlist-videos-container',
                prevNav: '.ml-playlist-nav.prev',
                prevNavIcon: '.ml-playlist-nav.prev .icon.icon-prev',
                nextNav: '.ml-playlist-nav.next',
                nextNavIcon: '.ml-playlist-nav.next .icon.icon-next',
                viewAllIcon: '.ml-playlist-nav.next .icon.icon-viewall',
                listContainer: '.ml-playlist-videos',
                mediaThumbnail: '.ml-video-thumbnail, .ml-audio-thumbnail'
            },
            footer: {
                playListFooterContainer: '.ml-playlist-footer-container',
                playListFooterPreloader: '.ml-playlist-footer-container .ml-playlist-preloader',
                playListFooterLoadMoreButton: '.ml-playlist-footer-container .ml-playlist-load-more',
                hiddenPlayListFooterPreloader: 'hidden'
            }
        };
        _self.dataAttribute = {
            playlists: 'playlists',
            displayNum: 'display-num',
            displayMedia: 'display-media',
            maxMedia: 'max-media',
            playlistGuid: 'playlist-guid',
            totalMedia: 'total-media'
        };
        _self.otherAttribute = {
            currentIndex: 'data-current-index'
        };
        _self.ajaxUrl = '/api/custom/careers/playlistcollection/partialplaylistcollection';
    };
    _self.loadAjax = function (_obj, elm, callbackInit, callbackOnSuccess, callbackOnFail) {
        callbackInit();
        var $playlistCollection = jQuery(elm).closest(_obj.parentClass);
        if ($playlistCollection && $playlistCollection.length > 0) {
            var playlistItems = $playlistCollection.data(_obj.dataAttribute.playlists);
            var displayNum = $playlistCollection.data(_obj.dataAttribute.displayNum);
            var nextIndex = parseInt($playlistCollection.attr(_obj.otherAttribute.currentIndex)) + 1;
            var displayNumberOfMediaItems = $playlistCollection.data(_obj.dataAttribute.displayMedia);
            var maxNumberOfMediaItems = $playlistCollection.data(_obj.dataAttribute.maxMedia);

            jQuery.ajax({
                url: _obj.ajaxUrl,
                data: {
                    playlistItems: playlistItems,
                    displayNum: displayNum,
                    currentIndex: nextIndex,
                    displayNumberOfMediaItems: displayNumberOfMediaItems,
                    maxNumberOfMediaItems: maxNumberOfMediaItems
                },
                type: "GET",
                success: function (data) {
                    if (data && data.trim().length > 0) {
                        callbackOnSuccess(data, nextIndex);
                    }
                    else {
                        callbackOnFail();
                    }
                },
                error: function (msg) {
                    callbackOnFail();
                }
            });
        }
    };
    _self.refreshShowMoreButton = function (_obj, elm) {
        var $playlistColl = jQuery(elm);
        var $playlistItems = $playlistColl.data(_obj.dataAttribute.playlists);
        if ($playlistItems) {
            var total = ($playlistItems.match(/\|/g) || []).length + 1;
            var currentIndex = parseInt($playlistColl.attr(_obj.otherAttribute.currentIndex));
            var displayNum = $playlistColl.data(_obj.dataAttribute.displayNum);

            if (total > (currentIndex + 1) * displayNum) { // display 'show more' button
                $playlistColl.find(_obj.childClass.footer.playListFooterContainer).removeClass(_obj.childClass.footer.hiddenPlayListFooterPreloader);
                $playlistColl.closest(_obj.parentContainerClass).css('border-bottom', 'none');
            }
            else { // hide 'show more' button
                $playlistColl.find(_obj.childClass.footer.playListFooterContainer).addClass(_obj.childClass.footer.hiddenPlayListFooterPreloader);
                $playlistColl.closest(_obj.parentContainerClass).css('border-bottom', '#000 solid 7px');
            }
        }
    };
    _self.refresh = function () {
        var handleMultipleInstance = function () {
            jQuery(_self.childClass.rowClass).each(function (index) {
                var horizontalPlaylistInstance = jQuery(this);
                horizontalPlaylistInstance.removeClass('hidden');

                _self.eventHandlers.onClick.init(horizontalPlaylistInstance);
                _self.renderMobileMedia(horizontalPlaylistInstance);
                _self.toggleMobileElements(horizontalPlaylistInstance);
                _self.setMediaIndex(horizontalPlaylistInstance);

                _self.setPrevNextNavDistance(horizontalPlaylistInstance);
                jQuery(window).on('resize', function () {
                    _self.setPrevNextNavDistance(horizontalPlaylistInstance);
                });
            });
        };

        handleMultipleInstance();
    };
    _self.init = function () {
        _self.eventHandlers.onResize.init();
        jQuery(_self.parentClass).each(function (index) {
            _self.refreshShowMoreButton(_self, this);
            var $elm = jQuery(this).find(_self.childClass.footer.playListFooterLoadMoreButton);
            if ($elm && $elm.length > 0) {
                $elm.click(function () {
                    _self.eventHandlers.onClickLoadMore.init(this);
                    return false;
                });
            }
        });
    };

    _self.loadDefaults();

    return _self;
};
var mlLightBox = function () {
    var _self = this;
    var _templates = function (parent) {
        var _obj = this;

        _obj.parent = parent;
        _obj.helpers = new mlHelpers();
        _obj.loadDefaults = function () {
            _obj.defaultVideoHeight = '421px';
            _obj.defaultAudioHeight = '30px';
            _obj.defaultImageHeight = 'auto';
            _obj.defaultImageWidth = 'auto';
            _obj.mediaBoxStyleForImage = {
                width: 'auto',
                height: 'auto'
            };
            _obj.childClass = {
                title: '.video-title',
                description: '.full.description',
                seeMore: '.seeHTML',
                seeMoreIcon: '.icon.icon-chevron',
                closeButton: '.close-btn-container',
                closeButtonIcon: '.close-btn',
                prevNavButton: '.prev-button',
                nextNavButton: '.next-button'
            };
        };
        _obj.setActiveMedia = function (mediaAttributes) {
            var media = '';
            var getMediaForVideo = function () {
                var source = jQuery('<source>').attr({
                    src: mediaAttributes.mediaLink,
                    type: 'video/mp4'
                });
                media = jQuery('<video>').attr({
                    width: '100%',
                    height: '100%'
                }).append(source);

                if (_obj.helpers.isYoutubeUrl(mediaAttributes.mediaLink)) {
                    var url = _obj.helpers.getYoutubeUrlForMediaElements(mediaAttributes.mediaLink);
                    source = jQuery('<source>').attr({
                        src: url,
                        type: 'video/youtube'
                    });

                    media = jQuery('<video>').attr({
                        width: '100%',
                        height: '100%',
                        id: 'mlPlayer' + ((Math.random() * 100).toFixed(0) * (Math.random() * 10)).toFixed(0),
                        preload: 'none'
                    }).append(source);
                }
                if (_obj.helpers.isVimeoUrl(mediaAttributes.mediaLink)) {
                    var url = _obj.helpers.getVimeoUrlForMediaElements(mediaAttributes.mediaLink);
                    source = jQuery('<source>').attr({
                        src: url,
                        type: 'video/vimeo'
                    });

                    media = jQuery('<video>').attr({
                        width: '100%',
                        height: '100%',
                        id: 'mlPlayer' + ((Math.random() * 100).toFixed(0) * (Math.random() * 10)).toFixed(0),
                        preload: 'none'
                    }).append(source);
                }
            };
            var getMediaForAudio = function () {
                var source = jQuery('<source>').attr({
                    src: mediaAttributes.mediaLink,
                    type: 'audio/mp3'
                });
                media = jQuery('<audio>').attr({
                    width: '100%',
                    height: '100%'
                }).append(source);
            };
            var getMediaForImage = function () {
                media = jQuery('<img>').attr({
                    alt: mediaAttributes.mediaLink,
                    src: mediaAttributes.mediaLink
                });
            };
            var mediaModal = jQuery(_obj.parent.parentClass);
            var mediaBox = mediaModal.find(_obj.parent.childClass.mediaBox);
            var mediaContent = mediaBox.find(_obj.parent.childClass.mediaContent);
            var mediaContainer = mediaBox.find(_obj.parent.childClass.mediaContainer);
            var mediaTitleElement = mediaBox.find(_obj.childClass.title);
            var mediaDescriptionElement = mediaBox.find(_obj.childClass.description);
            var seeMoreElement = _obj.getSeeMoreElement(mediaAttributes.ctaLink, mediaAttributes.ctaText);

            mediaContent.empty();
            mediaContent.removeAttr('style');
            mediaTitleElement.text(mediaAttributes.mediaTitle);
            mediaDescriptionElement.remove(_obj.childClass.seeMore);
            mediaDescriptionElement.text(mediaAttributes.mediaDescription);

            if (mediaAttributes.mediaDescription) {
                mediaDescriptionElement.append(seeMoreElement);
            }

            if (mediaAttributes.mediaType === "video") {
                getMediaForVideo();
                mediaContent.css({ height: _obj.defaultVideoHeight });
            } else if (mediaAttributes.mediaType === "audio") {
                getMediaForAudio();
                mediaContent.css({ height: _obj.defaultAudioHeight });
            } else if (mediaAttributes.mediaType === "photo" || mediaAttributes.mediaType === "image") {
                getMediaForImage();
                mediaContent.css({ height: _obj.defaultImageHeight, width: _obj.defaultImageWidth });
            }

            mediaContent.append(media);
            // change this
            mediaDescriptionElement.css({ width: mediaContent.width() });
        };
        _obj.showLoadingIcon = function () {
            var mediaModal = jQuery(_obj.parent.parentClass);
            var mediaBox = mediaModal.find(_obj.parent.childClass.mediaBox);
            var rotatingContainer = mediaModal.find(_obj.parent.childClass.rotatingContainer);
            mediaBox.addClass('hidden');
            rotatingContainer.removeClass('hidden');
        };
        _obj.hideLoadingIcon = function () {
            var mediaModal = jQuery(_obj.parent.parentClass);
            var mediaBox = mediaModal.find(_obj.parent.childClass.mediaBox);
            var rotatingContainer = mediaModal.find(_obj.parent.childClass.rotatingContainer);
            mediaBox.removeClass('hidden');
            rotatingContainer.addClass('hidden');
        };
        _obj.getSeeMoreElement = function (ctaLink, ctaText) {
            var seeMoreElement = '';
            if (ctaLink && ctaText) {
                var anchor = jQuery('<a>').addClass(_obj.helpers.removeDotNotation(_obj.childClass.seeMore)).text(ctaText);
                anchor.attr('href', ctaLink);
                anchor.attr('title', ctaText);
                anchor.attr('aria-label', ctaText);
                var icon = jQuery('<span>').addClass(_obj.helpers.removeDotNotation(_obj.childClass.seeMoreIcon));
                icon.attr('aria-hidden', 'true');
                seeMoreElement = anchor.prepend(icon);
            }

            return seeMoreElement;
        };
        _obj.getCloseButtonElement = function () {
            var btn = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.childClass.closeButton));
            var icon = jQuery('<span>&#x2715;</span>').addClass(_obj.helpers.removeDotNotation(_obj.childClass.closeButtonIcon));

            btn.append(icon);
            icon.click(function (e) {
                _obj.parent.close();
            });

            return btn;
        };
        _obj.getModalView = function (mediaType, mediaLink, mediaSource, mediaTitle, mediaDescription, ctaLink, ctaText) {
            var media = '';
            var getMediaForVideo = function () {
                var source = jQuery('<source>').attr({
                    src: mediaLink,
                    type: 'video/mp4'
                });
                media = jQuery('<video>').attr({
                    width: '100%',
                    height: '100%'
                }).append(source);

                if (_obj.helpers.isYoutubeUrl(mediaLink)) {
                    var url = _obj.helpers.getYoutubeUrlForMediaElements(mediaLink);
                    source = jQuery('<source>').attr({
                        src: url,
                        type: 'video/youtube'
                    });

                    media = jQuery('<video>').attr({
                        width: '100%',
                        height: '100%',
                        id: 'mlPlayer' + ((Math.random() * 100).toFixed(0) * (Math.random() * 10)).toFixed(0),
                        preload: 'none'
                    }).append(source);
                }
                if (_obj.helpers.isVimeoUrl(mediaLink)) {
                    var url = _obj.helpers.getVimeoUrlForMediaElements(mediaLink);
                    source = jQuery('<source>').attr({
                        src: url,
                        type: 'video/vimeo'
                    });

                    media = jQuery('<video>').attr({
                        width: '100%',
                        height: '100%',
                        id: 'mlPlayer' + ((Math.random() * 100).toFixed(0) * (Math.random() * 10)).toFixed(0),
                        preload: 'none'
                    }).append(source);
                }
            };
            var getMediaForAudio = function () {
                var source = jQuery('<source>').attr({
                    src: mediaLink,
                    type: 'audio/mp3'
                });
                media = jQuery('<audio>').attr({
                    width: '100%',
                    height: '100%'
                }).append(source);
            };
            var getMediaForImage = function () {
                media = jQuery('<img>').attr({
                    alt: mediaLink,
                    src: mediaLink
                });
            };
            var seeMoreElement = _obj.getSeeMoreElement(ctaLink, ctaText);
            var closeButtonElement = _obj.getCloseButtonElement();
            var mediaModal = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.parent.parentClass));
            var mediaBox = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.mediaBox));
            var mediaOverlay = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.overlay));
            var mediaContent = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.mediaContent));
            var mediaContainer = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.mediaContainer));
            var mediaTitleElement = jQuery('<h4>').addClass(_obj.helpers.removeDotNotation(_obj.childClass.title)).text(mediaTitle);
            var mediaDescriptionElement = jQuery('<p>').addClass(_obj.helpers.removeDotNotation(_obj.childClass.description)).text(mediaDescription);

            mediaModal.attr('role', 'dialog');
            mediaModal.attr('aria-modal', 'true');
            
            if (mediaDescription) {
                mediaDescriptionElement.append(seeMoreElement);
            }
            if (mediaType === "video") {
                getMediaForVideo();
                mediaContent.css({ height: _obj.defaultVideoHeight });
            } else if (mediaType === "audio") {
                getMediaForAudio();
                mediaContent.css({ height: _obj.defaultAudioHeight });
            } else if (mediaType === "photo" || mediaType === "image") {
                getMediaForImage();
                mediaContent.css({ height: _obj.defaultImageHeight, width: _obj.defaultImageWidth });
            }

            media.attr('aria-label', mediaTitle);
            mediaContent.append(media);
            mediaContainer.append(mediaContent, mediaTitleElement, mediaDescriptionElement, closeButtonElement);
            mediaBox.append(mediaContainer);
            mediaModal.append(mediaOverlay, mediaBox);

            return mediaModal;
        };
        _obj.getModalViewWithCarousel = function (mediaAttributes) {
            var media = '';
            var getMediaForVideo = function () {
                var source = jQuery('<source>').attr({
                    src: mediaAttributes.mediaLink,
                    type: 'video/mp4'
                });
                media = jQuery('<video>').attr({
                    width: '100%',
                    height: '100%'
                }).append(source);

                if (_obj.helpers.isYoutubeUrl(mediaAttributes.mediaLink)) {
                    var url = _obj.helpers.getYoutubeUrlForMediaElements(mediaAttributes.mediaLink);
                    source = jQuery('<source>').attr({
                        src: url,
                        type: 'video/youtube'
                    });

                    media = jQuery('<video>').attr({
                        width: '100%',
                        height: '100%',
                        id: 'mlPlayer' + ((Math.random() * 100).toFixed(0) * (Math.random() * 10)).toFixed(0),
                        preload: 'none'
                    }).append(source);
                }
                if (_obj.helpers.isVimeoUrl(mediaAttributes.mediaLink)) {
                    var url = _obj.helpers.getVimeoUrlForMediaElements(mediaAttributes.mediaLink);
                    source = jQuery('<source>').attr({
                        src: url,
                        type: 'video/vimeo'
                    });

                    media = jQuery('<video>').attr({
                        width: '100%',
                        height: '100%',
                        id: 'mlPlayer' + ((Math.random() * 100).toFixed(0) * (Math.random() * 10)).toFixed(0),
                        preload: 'none'
                    }).append(source);
                }
            };
            var getMediaForAudio = function () {
                var source = jQuery('<source>').attr({
                    src: mediaAttributes.mediaLink,
                    type: 'audio/mp3'
                });
                media = jQuery('<audio>').attr({
                    width: '100%',
                    height: '100%'
                }).append(source);
            };
            var getMediaForImage = function () {
                media = jQuery('<img>').attr({
                    alt: mediaAttributes.mediaLink,
                    src: mediaAttributes.mediaLink
                });
            };
            var seeMoreElement = _obj.getSeeMoreElement(mediaAttributes.ctaLink, mediaAttributes.ctaText);
            var closeButtonElement = _obj.getCloseButtonElement();
            var mediaModal = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.parent.parentClass));
            var mediaBox = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.mediaBox));
            var mediaOverlay = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.overlay));
            var mediaContent = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.mediaContent));
            var mediaContainer = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.mediaContainer));
            var mediaTitleElement = jQuery('<h4>').addClass(_obj.helpers.removeDotNotation(_obj.childClass.title)).text(mediaAttributes.mediaTitle);
            var mediaDescriptionElement = jQuery('<p>').addClass(_obj.helpers.removeDotNotation(_obj.childClass.description)).text(mediaAttributes.mediaDescription);
            var carouselPrevNav = jQuery('<a>').addClass(_obj.helpers.removeDotNotation(_obj.childClass.prevNavButton)).click(function (e) { _obj.parent.PrevMedia(); });
            var carouselNextNav = jQuery('<a>').addClass(_obj.helpers.removeDotNotation(_obj.childClass.nextNavButton)).click(function (e) { _obj.parent.NextMedia(); });
            var rotatingContainer = jQuery('<div>').addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.rotatingContainer)).addClass('hidden');
            var rotatingIcon = jQuery('<i>').addClass(_obj.helpers.removeDotNotation(_obj.parent.childClass.rotatingIcon));

            rotatingContainer.append(rotatingIcon);

            if (mediaAttributes.mediaDescription) {
                mediaDescriptionElement.append(seeMoreElement);
            }

            if (mediaAttributes.mediaType === "video") {
                getMediaForVideo();
                mediaContent.css({ height: _obj.defaultVideoHeight });
            } else if (mediaAttributes.mediaType === "audio") {
                getMediaForAudio();
                mediaContent.css({ height: _obj.defaultAudioHeight });
            } else if (mediaAttributes.mediaType === "photo" || mediaAttributes.mediaType === "image") {
                getMediaForImage();
                mediaContent.css({ height: _obj.defaultImageHeight, width: _obj.defaultImageWidth });
            }

            mediaContent.append(media);
            mediaContainer.append(carouselPrevNav, mediaContent, mediaTitleElement, mediaDescriptionElement, closeButtonElement, carouselNextNav);
            mediaBox.append(mediaContainer);
            mediaOverlay.append(rotatingContainer);
            mediaModal.append(mediaOverlay, mediaBox);

            return mediaModal;
        };
        _obj.loadDefaults();

        return _obj;
    };

    _self.playlistId = '';
    _self.totalMediaItems = 0;
    _self.activeMedia = {};
    _self.mediaList = [];
    _self.lightBoxInstance = null;
    _self.helpers = new mlHelpers();
    _self.loadDefaults = function () {
        _self.parentClass = '.ml-video-modal';
        _self.childClass = {
            overlay: '.ml-bg-overlay',
            mediaBox: '.ml-media-box',
            mediaContent: '.ml-media-content',
            mediaContainer: '.ml-media-container',
            rotatingContainer: '.ml-load-container',
            rotatingIcon: '.fa.fa-spinner.fa-pulse.fa-3x'
        };
    };
    _self.templates = new _templates(_self);
    _self.mediaElementPluginHandler = new mlMediaElementPluginHandler(_self);
    _self.centerVideoPlayer = function (lightBoxInstance) {
        var mediaBoxInstance = jQuery(lightBoxInstance.find(_self.childClass.mediaBox).first());
        var mediaContentInstance = jQuery(lightBoxInstance.find(_self.childClass.mediaContent).first());
        var mediaContainerInstance = jQuery(lightBoxInstance.find(_self.childClass.mediaContainer).first());
        var mediaDescriptionElement = jQuery(lightBoxInstance.find(_self.templates.childClass.description).first());
        var prevButton = jQuery(lightBoxInstance.find(_self.templates.childClass.prevNavButton).first());
        var nextButton = jQuery(lightBoxInstance.find(_self.templates.childClass.nextNavButton).first());
        var currentHeight = mediaBoxInstance.prop('clientHeight');
        var offsetHeight = (parseInt(mediaContainerInstance.prop('clientHeight')) / 2);

        mediaBoxInstance.removeAttr('style');
        mediaDescriptionElement.removeAttr('style');

        var offsetHeightStyleValue = offsetHeight ? 'calc(50% - ' + offsetHeight + 'px)' : '27%';
        mediaContainerInstance.css({ top: offsetHeightStyleValue });

        if (mediaContentInstance != null && mediaContentInstance.width > 0) {
            mediaDescriptionElement.css({ width: mediaContentInstance.width() });
        }

        if (_self.activeMedia.mediaType == 'image' || _self.activeMedia.mediaType == 'photo') {
            var val = mediaContainerInstance.find('> img').width();
            val = (val / 2) * -1;
            // is this used for anything??
        }

        if (_self.activeMedia.mediaIndex == _self.totalMediaItems - 1) {
            _self.lightBoxInstance.find(_self.templates.childClass.nextNavButton).hide();
        }

        if (_self.activeMedia.mediaIndex == 0) {
            _self.lightBoxInstance.find(_self.templates.childClass.prevNavButton).hide();
        }
    };
    _self.open = function (mediaType, mediaLink, mediaSource, mediaTitle, mediaDescription, ctaLink, ctaText) {
        if (!_self.helpers.isMobileView()) {
            jQuery(_self.parentClass).empty().remove();
            var lightBoxInstance = _self.templates.getModalView(mediaType, mediaLink, mediaSource, mediaTitle, mediaDescription, ctaLink, ctaText);
            jQuery('body').append(lightBoxInstance);
            lightBoxInstance.show(400);
            _self.mediaElementPluginHandler.initializePlugin(lightBoxInstance.find('video,audio'), true);
            _self.centerVideoPlayer(lightBoxInstance);
            _self.closeLightBox(lightBoxInstance.find('.ml-media-box'));
        }
    };
    _self.openWithCarousel = function (mediaAttributes, playlistItems, playlistId, totalMediaItems) {
        if (!_self.helpers.isMobileView()) {
            _self.activeMedia = mediaAttributes;
            _self.mediaList = playlistItems;

            if (typeof playlistId !== 'undefined' && playlistId) {
                _self.playlistId = playlistId;
            }

            if (typeof totalMediaItems !== 'undefined' && totalMediaItems) {
                _self.totalMediaItems = totalMediaItems;
            }
            else {
                _self.totalMediaItems = playlistItems.length;
            }

            jQuery(_self.parentClass).empty().remove();
            var lightBoxInstance = _self.templates.getModalViewWithCarousel(mediaAttributes);
            _self.lightBoxInstance = lightBoxInstance;
            jQuery('body').append(lightBoxInstance);
            lightBoxInstance.show(400);
            _self.mediaElementPluginHandler.initializePlugin(lightBoxInstance.find('video,audio'), true);
            _self.centerVideoPlayer(lightBoxInstance);
            _self.closeLightBox(lightBoxInstance.find('.ml-media-box'));
        }
    };
    _self.close = function () {
        jQuery(_self.parentClass).fadeOut(200, function () {
            setTimeout(function () {
                jQuery(_self.parentClass).empty().remove();
            }, 200);
        });
    };
    _self.closeLightBox = function (lightBoxInstance) {
        lightBoxInstance.click(function (e) {
            if (!jQuery(e.target).closest('.ml-media-container')[0]) {
                _self.close();
            }
        });
    };

    _self.PrevMedia = function () {
        if (_self.activeMedia.mediaIndex > 0) {
            var newIndex = _self.activeMedia.mediaIndex - 1;
            _self.activeMedia = _self.mediaList[newIndex];
            _self.templates.setActiveMedia(_self.activeMedia);
            _self.mediaElementPluginHandler.initializePlugin(_self.lightBoxInstance.find('video,audio'), true);
            _self.centerVideoPlayer(_self.lightBoxInstance);

            if (_self.activeMedia.mediaIndex == 0) {
                _self.lightBoxInstance.find(_self.templates.childClass.prevNavButton).hide();
            } else {
                _self.lightBoxInstance.find(_self.templates.childClass.prevNavButton).show();
            }
            _self.lightBoxInstance.find(_self.templates.childClass.nextNavButton).show();
        }
    };
    _self.NextMedia = function () {
        var newIndex = _self.activeMedia.mediaIndex + 1;
        if (newIndex >= _self.mediaList.length && newIndex < _self.totalMediaItems) {
            _self.loadAjax(_self.playlistId, newIndex,
                function () { // on Init
                    _self.lightBoxInstance.find(_self.templates.childClass.nextNavButton).hide();
                    _self.lightBoxInstance.find(_self.templates.childClass.prevNavButton).hide();
                    _self.templates.showLoadingIcon();
                },
                function (data) { // on Success
                    _self.addMediaItem(data.MediaRequest, newIndex);

                    if (data.MediaNext) {
                        _self.addMediaItem(data.MediaNext, newIndex + 1);
                    }

                    _self.lightBoxInstance.find(_self.templates.childClass.nextNavButton).show();
                    _self.lightBoxInstance.find(_self.templates.childClass.prevNavButton).show();
                    _self.templates.hideLoadingIcon();
                    displayNextMedia();
                },
                function (msg) { // on Fail
                    console.log(msg);
                    _self.lightBoxInstance.find(_self.templates.childClass.prevNavButton).show();
                    _self.templates.hideLoadingIcon();
                }
            );
        }
        else {
            displayNextMedia();
        }

        function displayNextMedia() {
            if (newIndex < _self.mediaList.length) {
                _self.activeMedia = _self.mediaList[newIndex];
                _self.templates.setActiveMedia(_self.activeMedia);
                _self.mediaElementPluginHandler.initializePlugin(_self.lightBoxInstance.find('video,audio'), true);
                _self.centerVideoPlayer(_self.lightBoxInstance);

                if (newIndex == (_self.totalMediaItems - 1)) {
                    _self.lightBoxInstance.find(_self.templates.childClass.nextNavButton).hide();
                } else {
                    _self.lightBoxInstance.find(_self.templates.childClass.nextNavButton).show();
                }

                _self.lightBoxInstance.find(_self.templates.childClass.prevNavButton).show();
            }
        }
    };
    _self.loadAjax = function (playlistId, index, callbackInit, callbackOnSuccess, callbackOnFail) {
        callbackInit();
        jQuery.ajax({
            url: "/api/custom/careers/playlist/playlistinfo",
            data: {
                id: playlistId,
                index: index
            },
            type: "GET",
            dataType: 'json',
            success: function (data) {
                if (data) {
                    callbackOnSuccess(data);
                }
                else {
                    callbackOnFail("No more media items!");
                }
            },
            error: function (msg) {
                callbackOnFail(msg);
            }
        });
    };
    _self.addMediaItem = function (mediaRequest, mediaIndex) {
        var mediaAttributes = {
            mediaType: mediaRequest.MediaType,
            mediaLink: mediaRequest.MediaUrl,
            mediaSource: '',
            mediaTitle: mediaRequest.Title,
            mediaDescription: mediaRequest.Description,
            ctaLink: mediaRequest.SeeMoreLink,
            ctaText: mediaRequest.SeeMoreText,
            mediaIndex: mediaIndex
        };
        _self.mediaList.push(mediaAttributes);
    };
    _self.loadDefaults();

    return _self;
};
var mlRenderer = function () {
    var _self = this;
    _self.helpers = new mlHelpers();
    _self.mediaModule = new mlMediaModule();
    _self.verticalPlaylist = new mlVerticalPlaylist();
    _self.horizontalPlaylist = new mlHorizontalPlaylist();
    _self.carousel = new mlCarousel();
 

    var renderModule = function (url, elmSelector, attachEventHandler) {
        // REUSABLE RENDERER
        var $modules = jQuery(elmSelector);

        $modules.each(function (index) {
            var $instance = jQuery(this);
            var val = $instance.attr('value');
            var href = $instance.attr('href');
            var args = val ? val : href;

            if (elmSelector == ".contact-list.renderer") {
                // Detect Print
                var isPrint = GetParameterValues("p");
                if (typeof isPrint !== 'undefined') {
                    args = args + "|p";
                }
            }
            function onInit() {
                $instance.hide();
                replaceTagOfAscendants($instance, 'p', 'div');
            }

            function onSuccess(data) {
                $instance.replaceWith(data);
                attachEventHandler();
            }

            function onFailure() {
            }

            function GetParameterValues(param) {
                var url = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
                for (var i = 0; i < url.length; i++) {
                    var urlparam = url[i].split('=');
                    if (urlparam[0] == param) {
                        return urlparam[1];
                    }
                }
            }

            loadAjax(url, args, onInit, onSuccess, onFailure);
        });
    };
    var loadAjax = function (url, args, callbackOnInit, callbackOnSuccess, callbackOnFailure) {
        callbackOnInit();
        jQuery.ajax({
            url: url,
            data: {
                args: args
            },
            type: "GET",
            dataType: 'html',
            success: function (data) {
                if (data) {
                    callbackOnSuccess(data);
                }
            },
            error: function (msg) {
                callbackOnFailure();
            }
        });
    };
    var replaceTagOfAscendants = function (elm, from, to) {
        jQuery(elm).closest(from).each(function (index) {
            jQuery(this).contents().unwrap().wrap('<' + to + '/>');
        });
    };
    var renderMediaModule = function () {
        // MEDIA MODULE
        var url = '/api/custom/careers/mediamodule/loadmedia';
        var elmSelector = '.media-module.renderer';

        function attachEventHandler() {
            _self.mediaModule.Init();
        }

        renderModule(url, elmSelector, attachEventHandler);
    };
    var renderPlaylist = function () {
        // PLAYLIST
        var url = '/api/custom/careers/playlist/loadplaylist';
        var elmSelector = '.playlist.renderer';

        function attachEventHandler() {
            _self.verticalPlaylist.init();
            _self.horizontalPlaylist.refresh();
        }

        renderModule(url, elmSelector, attachEventHandler);
    };
    var renderCarousel = function () {
        // CAROUSEL
        var url = '/api/custom/careers/carousel/loadcarousel';
        var elmSelector = '.carousel.renderer';

        function attachEventHandler() {
            _self.carousel.init();
        }

        renderModule(url, elmSelector, attachEventHandler);
    };
    var renderInfographic = function () {
        // INFOGRAPHIC
        var url = '/api/custom/careers/infographic/loadinfographic';
        var elmSelector = '.infographic.renderer';

        function attachEventHandler() {
            MLCV.InfoGraphicModule.AttachEventHandlers();
        }

        renderModule(url, elmSelector, attachEventHandler);
    };
    var renderSpecialTopicTeamList = function () {
        // CONTACTLIST
        var url = '/api/custom/specialtopics/specialtopicteamlist/loadspecialtopicteamlist';
        var elmSelector = '.contact-list.renderer';

        function attachEventHandler() {
           
        }
        renderModule(url, elmSelector, attachEventHandler);
    };
    _self.init = function () {
        if (_self.helpers.isPageEditingMode())
            return;

        renderMediaModule();
        renderPlaylist();
        renderCarousel();
        renderInfographic();
        renderSpecialTopicTeamList();
    };
};
var mlLegacyLightBox = function () {
    var _self = this;

    _self.updateCloseButton = function (elm, elmContainer) {
        var btnClose = jQuery(elm);
        btnClose.hide();

        if (btnClose.length < 1) {
            return;
        }

        btnClose.addClass("updated-close-btn");
        btnClose.text("✕");

        // set offset
        window.setTimeout(function () {
            var container = jQuery(elmContainer);

            if (container.length < 1) {
                return;
            }

            var containerOffset = container.offset();
            var containerWidth = container.width();
            var computedTop = containerOffset.top - 40;
            var computedLeft = containerOffset.left + containerWidth;
            btnClose.offset({ top: computedTop, left: computedLeft });
            btnClose.show();
        }, 500);

    };
};

MLCV.Helpers = new mlHelpers();
MLCV.CarouselModule = new mlCarousel();
MLCV.VerticalPlaylist = new mlVerticalPlaylist();
MLCV.HorizontalPlaylist = new mlHorizontalPlaylist();
MLCV.MediaModule = new mlMediaModule();
MLCV.Renderer = new mlRenderer();
MLCV.LegacyLightBox = new mlLegacyLightBox();

/* MediaElement Plugin */
MLCV.MediaElementPlugin = {};
MLCV.MediaElementPlugin.ActivePlayer = null;
MLCV.MediaElementPlugin.WrapPlayers = function (target, onModal) {
    var originalNode;
    MLCV.MediaElementPlugin.ActivePlayer = new MediaElementPlayer(target, {
        videoWidth: '100%',
        videoHeight: (onModal) ? '100%' : this.videoHeight,
        success: function (mElement, oNode) {
            // Remove the pre-loader
            jQuery('.active-video-wrapper .pre-loader').remove();
            // Play the media
            if (onModal) {
                mElement.play();
            }

            mElement.addEventListener('playing', function () {
                MLCV.WebAnalytics.GA.TrackMediaPlay(oNode);
            }, false);
        },
        // fires when a problem is detected
        error: function () {
        }
    });
}

/* ModalDimmerModule (transferred from app.js) */
MLCV.ModalDimmerModule = {};
MLCV.ModalDimmerModule.Create = function (contentHolder, content, hasCustomClose) {
    jQuery('body').prepend(
        jQuery('<div>').addClass('ml-modal').append(
            jQuery('<div>').addClass('ml-modal-dimmer').click(function () {
                if (typeof hasCustomClose === 'undefined' || hasCustomClose == false) {
                    MLCV.ModalDimmerModule.Close();
                }
            }),
            jQuery(contentHolder).append(
                jQuery('<div>').addClass('ml-modal-content-holder prepare').append(
                    jQuery(content).addClass('ml-modal-content')
                )
            ).click(function () {
                if (typeof hasCustomClose === 'undefined' || hasCustomClose == false) {
                    MLCV.ModalDimmerModule.Close();
                }
            })
        )
    );

    jQuery('html').css({ 'overflow-y': 'hidden' });

    if (typeof hasCustomClose === 'undefined' || hasCustomClose == false) {
        MLCV.ModalDimmerModule.AppendCloseButton();
    }

    setTimeout(function () {
        jQuery('.prepare').removeClass('prepare');
    }, 100);

    return jQuery('.ml-modal');
};
MLCV.ModalDimmerModule.AppendCloseButton = function () {
    jQuery('.ml-modal-content-holder').append(
        jQuery('<div>').addClass('ml-modal-close-button-holder').append(
            jQuery('<span>&#x2715;</span>').addClass('close-button').click(function () {
                MLCV.ModalDimmerModule.Close();
            })
        )
    );
};
MLCV.ModalDimmerModule.Close = function () {
    jQuery('.ml-modal').fadeOut(400, function () {
        // Destroy the MediaElementPlayer on close of media modal
        if (MLCV.MediaElementPlugin.ActivePlayer)
            MLCV.MediaElementPlugin.ActivePlayer.remove();
        MLCV.MediaElementPlugin.ActivePlayer = null;

        jQuery('.ml-modal').remove();
        jQuery('html').css({ 'overflow-y': 'auto' });
    });
};

/* InfoGraphicModule (transferred from app.js) */
MLCV.InfoGraphicModule = {};
MLCV.InfoGraphicModule.AttachEventHandlers = function () {
    jQuery('.info-graphic-module .info-graphic-module-wrapper').each(function (index) {
        if (jQuery(this).hasClass('has-event'))
            return;

        jQuery(this).click(function () {
            $infoGraphicHolder = jQuery('<div>').addClass('info-graphic-modal-holder');
            if (jQuery(this).closest('.info-graphic-module').hasClass('ml-gateable')) {
                $infoGraphicHolder.addClass('ml-gated-infographic');
            }

            $thumbnail = jQuery(jQuery(this).find('img[dataimagetype="thumbnail"]').clone()[0]);
            $actualImage = jQuery(jQuery(this).find('img[dataimagetype="actual"]').clone()[0]);

            if ($thumbnail.length < 1) {
                $thumbnail = $actualImage;
            }

            $content = $thumbnail.attr({ src: $actualImage.attr('src') }).click(function (e) {
                e.preventDefault();
                return false;
            });
            $modal = MLCV.ModalDimmerModule.Create($infoGraphicHolder, $content);
        });

        jQuery(this).addClass('has-event');
    });
};

/* CareerTrajectory */
MLCV.CareerTrajectory = {};
MLCV.CareerTrajectory.AttachEventHandlers = function () {
    MLCV.CareerTrajectory.SetTimelineEventsWidth();

    jQuery(window).on('resize', function () {
        setTimeout(function () {
            MLCV.CareerTrajectory.SetTimelineEventsWidth();
            MLCV.CareerTrajectory.PositionEventIndicator();
        }, 200);
    });

    jQuery('.career-trajectory-module').each(function (index) {
        $timelineEvents = jQuery(this).find('.career-trajectory-module-timeline-events');
        $timelineItemContainer = jQuery(this).find('.career-trajectory-module-wrapper .career-trajectory-module-timeline');
        $timelineEvents.find('ul > li > a').each(function (indexA) {
            var trigger = (this);
            var id = jQuery(trigger).attr('href');
            var item = $timelineItemContainer.find(id);
            id = id + "" + index + "" + indexA;
            jQuery(item).attr('id', id.substr(1));
            jQuery(trigger).attr('href', id);
        });
    });

    jQuery('.career-trajectory-module .prev-holder').click(function () {
        $parent = jQuery(this).closest('.career-trajectory-module-wrapper');
        $timelineEvents = jQuery(this).siblings('.career-trajectory-module-timeline-events');
        $activeNode = jQuery($timelineEvents.find('ul > li > a.active')[0]);
        $nodeContainer = jQuery($activeNode.closest('li')[0]);
        $prevNode = $nodeContainer.prev('li');
        if ($prevNode.length > 0) {
            jQuery($prevNode.find('a')[0]).click();
        }
    });

    jQuery('.career-trajectory-module .next-holder').click(function () {
        $parent = jQuery(this).closest('.career-trajectory-module-wrapper');
        $timelineEvents = jQuery(this).siblings('.career-trajectory-module-timeline-events');
        $activeNode = jQuery($timelineEvents.find('ul > li > a.active')[0]);
        $nodeContainer = jQuery($activeNode.closest('li')[0]);
        $nextNode = $nodeContainer.next('li');
        if ($nextNode.length > 0) {
            jQuery($nextNode.find('a')[0]).click();
        }
    });

    jQuery('.career-trajectory-module-timeline-events > ul > li > a').click(function () {
        $timelineEvents = jQuery(this).closest('.career-trajectory-module-timeline-events');
        $timelineItemContainer = $timelineEvents.closest('.career-trajectory-module-wrapper').find('.career-trajectory-module-timeline');
        var trigger = jQuery(this);
        if (!jQuery(jQuery(trigger).attr("href")).hasClass('active')) {
            $hasActiveItem = $timelineItemContainer.find(".career-trajectory-module-timeline-item.active").length;
            $action = function () {
                $timelineEvents.find('ul > li > a.active').removeClass('active');
                trigger.addClass('active');
                jQuery(jQuery(trigger).attr("href")).fadeIn(400).addClass("active");
                MLCV.CareerTrajectory.PositionEventIndicator();
                //$timelineEvents.scrollLeft(jQuery(trigger).offset().left - $timelineEvents.offset().left);
            };

            if ($hasActiveItem > 0) {
                $timelineItemContainer.find(".career-trajectory-module-timeline-item.active").fadeOut(200, function () {
                    jQuery(this).removeClass('active');
                    $action();
                });
            } else {
                $action();
            }
        }

        return false;
    });

    jQuery('.career-trajectory-module-timeline-events').each(function (index) {
        $latestYear = 0;
        $currentItem = null;
        $currentYear = new Date().getFullYear();
        $timelineEvents = jQuery(this);
        $timelineItemContainer = $timelineEvents.closest('.career-trajectory-module-wrapper').find('.career-trajectory-module-timeline');
        $timelineEvents.find('ul > li > a').each(function (indexA) {
            var trigger = jQuery(this);
            var eventDate = jQuery(this).clone();
            var getLatestYear = function () {
                $latestYear = jQuery(eventDate).text();
                return trigger;
            };

            trigger.data('index', indexA);
            //jQuery(this).html(jQuery('<span>').html('&#9899;')).append(jQuery('<h3>').text(jQuery(eventDate).text()));
            jQuery(this).html(jQuery('<i>').addClass('pagination-item')).append(jQuery('<h3>').text(jQuery(eventDate).text()));
            MLCV.CareerTrajectory.PositionEventIndicator();

            $currentItem = ($currentYear == jQuery(eventDate).text() ? jQuery(this) : $currentItem);
            $currentItem = (jQuery(eventDate).text() < $currentYear && jQuery(eventDate).text() > $latestYear ? getLatestYear() : $currentItem);
        });

        if ($currentItem != null) {
            $currentItem.click();
        }
    });
};
MLCV.CareerTrajectory.PositionEventIndicator = function () {
    jQuery('.career-trajectory-module-timeline-events').each(function () {
        $timelineEvents = jQuery(this);
        if ($timelineEvents.find('ul > li > a.active').length > 0) {
            var events = $timelineEvents.find('ul > li > a').length - 1;
            var index = $timelineEvents.find('ul > li > a.active').data('index');
            var padding = 0;
            padding = (index == 0 ? 5 : padding);
            padding = (index == events ? -2 : padding);
            var val = $timelineEvents.find('ul > li > a.active').offset().left - $timelineEvents.find('.divider').offset().left;
            $timelineEvents.find('.divider .indicator').css({ left: val + padding });
            jQuery($timelineEvents).animate({
                scrollLeft: $timelineEvents.find('ul > li > a.active').offset().left - $timelineEvents.offset().left
            }, 400);
            if (index == 0) {
                $timelineEvents.siblings('.prev-holder').css({ 'opacity': '0.5', 'pointer-events': 'none' });
                $timelineEvents.siblings('.next-holder').css({ 'opacity': '1', 'pointer-events': 'auto' });
            } else if (index == events) {
                $timelineEvents.siblings('.prev-holder').css({ 'opacity': '1', 'pointer-events': 'auto' });
                $timelineEvents.siblings('.next-holder').css({ 'opacity': '0.5', 'pointer-events': 'none' });
            } else {
                $timelineEvents.siblings('.prev-holder').css({ 'opacity': '1', 'pointer-events': 'auto' });
                $timelineEvents.siblings('.next-holder').css({ 'opacity': '1', 'pointer-events': 'auto' });
            }
        }
    });
};
MLCV.CareerTrajectory.SetTimelineEventsWidth = function () {
    jQuery('.career-trajectory-module-timeline-events').each(function () {
        $timelineEvents = jQuery(this);
        var nodelength = $timelineEvents.find('ul > li').length;
        if (nodelength <= 1) {
            $timelineEvents.hide();
            $timelineEvents.prev('.prev-holder').hide();
            $timelineEvents.next('.next-holder').hide();
        }
        if (nodelength >= 1) {
            $timelineEvents.css({ 'overflow-x': 'hidden' });
        }
        $staticWidth = $timelineEvents.find('ul > li').length * ($timelineEvents.width() / ((nodelength >= 5) ? 5 : nodelength));
        $timelineEvents.find('ul').width($staticWidth);
        $timelineEvents.find('.divider').width(($staticWidth - 40));
        $timelineEvents.find('.divider').css({ left: "20px" });
    });
};

MLCV.Internationalization = {};
MLCV.Internationalization.AttachEventHandlers = function () {
    /* Start - Social Share Language Selector Events*/
    jQuery('.language-dropdown').on("click", function () {
        jQuery('.dropdown-content').toggle();
        jQuery('.dropdown-content').css({ 'position': 'absolute' });
    });

    jQuery('.language-dropdown').on("mouseleave", function () {
        jQuery('.dropdown-content').css({ 'display': 'none' });
    });
    /* End - Social Share Language Selector Events*/
};

/*Publication Series*/
MLCV.PublicationSeries = {};
MLCV.PublicationSeries.HideItems = function () {
    var numberOfItemToDisplay = jQuery('.c-pub-series__show-all-btn').data("number-to-display");
    jQuery('.pubseries .c-pub-series__block:gt(' + numberOfItemToDisplay + ')').addClass("hidden-cont");

    jQuery('.print-wrapper .c-pub-series__show-all-btn #pub-series-show-all').hide();
};

MLCV.PublicationSeries.Init = function () {
    MLCV.PublicationSeries.ToggleButton();
    MLCV.PublicationSeries.CheckBlockVisibility();
    MLCV.PublicationSeries.ToggleButtonVisibility();
};

MLCV.PublicationSeries.ToggleBlock = (function () {
    this.list = [];
    function add($block) {
        this.list.push($block);
    }
    return {
        add: add,
        list: this.list,
        showBlock: showBlock,
        hideBlock: hideBlock
    };

    function showBlock() {
        this.list.forEach(function ($object) {
            $object.show();
        });

    };

    function hideBlock() {
        this.list.forEach(function ($object) {
            $object.hide();
        });

    };

})();

MLCV.PublicationSeries.ToggleButton = function () {
    
    jQuery(".c-pub-series__show-all-btn #pub-series-show-all").click(function () {
        if (jQuery(this).hasClass("expand")) {
            jQuery(this).removeClass("expand");
            jQuery(this).parent().parent().find(".hidden-cont").slideUp();
            jQuery('#pub-series-show-all').attr('value', 'Show All');    
            MLCV.PublicationSeries.ToggleBlock.hideBlock();
        } else {
            jQuery(this).parent().parent().find(".hidden-cont").slideDown();
            jQuery(this).addClass("expand");
            jQuery('#pub-series-show-all').attr('value', jQuery(this).data('show-less'));
            MLCV.PublicationSeries.ToggleBlock.showBlock();
        }

        var showText = jQuery(this).attr("data-show");
        var hideText = jQuery(this).attr("data-hide");
        if (jQuery(this).text() == showText) {
            jQuery(this).text(hideText);
        } else {
            jQuery(this).text(showText);
        }
    });
};


MLCV.PublicationSeries.CheckBlockVisibility = function () {
    var pubSeriesBlockTitle = jQuery('.c-pub-series__series-title');
    pubSeriesBlockTitle.parent().each(function () {
        var a = jQuery(this).find('.c-pub-series__block');
        var isAllHidden = true;
        a.each(function () {
            if (jQuery(this).css("display") === 'block') {
                isAllHidden = false; 
            }
        });
        
        if (isAllHidden) {
            jQuery(this).hide();
            MLCV.PublicationSeries.ToggleBlock.add(jQuery(this));
        } 
    });
};


MLCV.PublicationSeries.ToggleButtonVisibility = function () {
    var hiddenBlockItemCount = jQuery('.c-pub-series__block.hidden-cont').length;
    var blockItemCount = jQuery('.c-pub-series__block').length;
    var isPrintMode = jQuery('body.is-print-mode').length;    

    if (hiddenBlockItemCount === 0 || blockItemCount === 0 || isPrintMode ===1) {
        return jQuery('#pub-series-show-all').hide();
    }

    else {
        jQuery('#pub-series-show-all').show();
    }

};

MLCV.TeamListPrintViewLayout = {};
MLCV.TeamListPrintViewLayout.Init = function () {
    MLCV.TeamListPrintViewLayout.Mobile();
};

MLCV.TeamListPrintViewLayout.Mobile = function () {
    var timesRun = 0;
    var interval = setInterval(function () {
        timesRun += 1;
        if (timesRun === 30) {
            clearInterval(interval);
        }

        //Checks if single column exists
        if (MLCV.TeamListPrintViewLayout.hasSingleColumnClass()) {
            MLCV.TeamListPrintViewLayout.addSingleColumnClass();
            MLCV.TeamListPrintViewLayout.mobileLayout();
        }

    }, 200);
};

//Checks if single column exists
MLCV.TeamListPrintViewLayout.hasSingleColumnClass = function () {
    var singleColumnSelector = jQuery('.single-column');
    if (singleColumnSelector.length > 0) {
        return true;
    }
};

//Add single column class indicator to special-topic container divs
MLCV.TeamListPrintViewLayout.addSingleColumnClass = function () {
    jQuery('.special-topic').parent().addClass('single-column-page');
};

MLCV.TeamListPrintViewLayout.mobileLayout = function () {
    var selector = {
        singleColumnClassIndicator: '.single-column-page',
        showImage: '.show-image',
        blockHeader: '.block-header',
        headingThree: 'h3',
        colOne: '.col1',
        colTwo: '.col2',
        colThree: '.col3',
        colStack: '.col-stack',
        thumbnailDetail: '.thumbnail-detail',
        specialTopic: '.special-topic',
        contactLabelHeadingTwo: 'h2',
        thumbnailDetailSingleColumn: '.thumbnail-detail--single-col',
        paragraph: 'p',
        cssClass: {
            showImage: 'show-image',
            hideImage: 'hide-image',
            blockHeaderTitle: 'c-block-header--tl-title',
            colOneStyling: 'col1',
            colTwoStyling: 'col2',
            colThreeStyling: 'col3',
            colStackStyling: 'col-stack',
            thumbnailDetailSingleColumn: 'thumbnail-detail--single-col',
            mobileContainerLayout: 'c-special-topic__container--sc',
            contactLabelHeadingTwoStyling: 'c-special-topic__title--sc',
            blockHeader: 'c-special-topic__block-header--sc',
            nameLink: 'c-special-topic__name',
            position: 'c-special-topic__position--sc'
        }
    };

    //Hide all images in team lists
    jQuery(selector.singleColumnClassIndicator).find(selector.showImage).removeClass(selector.cssClass.showImage).addClass(selector.cssClass.hideImage);

    //Add class to block header title h3
    jQuery(selector.singleColumnClassIndicator).find(selector.blockHeader).find(selector.headingThree).addClass(selector.cssClass.blockHeaderTitle);

    //Remove custom col1,col2,col3 styling
    jQuery(selector.singleColumnClassIndicator).find(selector.colOne).removeClass(selector.cssClass.colOneStyling);
    jQuery(selector.singleColumnClassIndicator).find(selector.colTwo).removeClass(selector.cssClass.colTwoStyling);
    jQuery(selector.singleColumnClassIndicator).find(selector.colThree).removeClass(selector.cssClass.colThreeStyling);
    jQuery(selector.singleColumnClassIndicator).find(selector.colStack).removeClass(selector.cssClass.colStackStyling);

    //Change width to 100%
    jQuery(selector.singleColumnClassIndicator).find(selector.thumbnailDetail).addClass(selector.cssClass.thumbnailDetailSingleColumn);

    //Change background-color of team list container to transparent on mobile
    jQuery(selector.singleColumnClassIndicator).find(selector.specialTopic).addClass(selector.cssClass.mobileContainerLayout);

    //Change font color of team list container title to #000
    jQuery(selector.singleColumnClassIndicator).find(selector.contactLabelHeadingTwo).addClass(selector.cssClass.contactLabelHeadingTwoStyling);

    //Add class to block header specific to single column layout
    jQuery(selector.singleColumnClassIndicator).find(selector.blockHeader).addClass(selector.cssClass.blockHeader);

    //Name styling
    jQuery(selector.singleColumnClassIndicator).find(selector.thumbnailDetailSingleColumn).find(selector.headingThree).addClass(selector.cssClass.nameLink);

    //Title styling
    jQuery(selector.singleColumnClassIndicator).find(selector.thumbnailDetailSingleColumn).find(selector.paragraph).addClass(selector.cssClass.position);
};


//-----------ALUMNI CAROUSEL START -------------//
MLCV.Spotlight = {};
MLCV.Spotlight.Init = function () {
        MLCV.Spotlight.ApplyMobileCarousel();
        MLCV.Spotlight.IsActiveThumbnail();
        MLCV.Spotlight.MobileCarouselControls();
        MLCV.Spotlight.OnResize();
};

//Adjust width of image container in mobile
MLCV.Spotlight.ApplyMobileCarousel = function () {

    var selector = {
        thumbnail: '.c-alumni__thumbnail.ml-native-block'
    };

    if (MLCV.Spotlight.IsMobile()) {
        jQuery(selector.thumbnail).css('width', '90%');
    }

    if (MLCV.Spotlight.IsMobileSmallMobile()) {
        jQuery(selector.thumbnail).css('width', '100%');
    } 

};


MLCV.Spotlight.IsMobile = function () {
    if (jQuery(window).width() <= 640) {
        return true;
    }

};

MLCV.Spotlight.IsMobileSmallMobile = function () {
    if (jQuery(window).width() <= 500) {
        return true;
    }

};


//Shows active thumbnail and hides other items in mobile carousel
MLCV.Spotlight.IsActiveThumbnail = function (direction) {
    if (MLCV.Spotlight.IsMobile()) {

        var selector = {
            thumbnail: '.c-alumni__thumbnail',
            activeThumbnail: '.c-active-thumbnail',
            cssClass: {
                activeThumbnail:'c-active-thumbnail'
            }
        };


        jQuery(selector.thumbnail).each(function () {
            hasActiveClass = jQuery(this).find(selector.activeThumbnail).hasClass(selector.cssClass.activeThumbnail);
            if (MLCV.Spotlight.IsMobile()) {
                if (hasActiveClass) {
                    jQuery(this).removeClass('hidden').show();

                    var thumbnailDirection = jQuery(selector.activeThumbnail).parent();
                    if (direction == 'previous') {   
                        thumbnailDirection.removeClass('block--silde-left l-opacity-1');
                        thumbnailDirection.addClass('block--silde-right');
                        
                    }

                    if (direction == 'next') {
                        thumbnailDirection.removeClass('block--silde-right l-opacity-1');
                        thumbnailDirection.addClass('block--silde-left');

                    }

                    if (typeof direction == 'undefined') {
                        jQuery(selector.activeThumbnail).parent().addClass('l-opacity-1');
                    }

                } else {
                    jQuery(this).hide();
                }
            } else {
                jQuery(this).show();

            }

        });
    }
};

//Controls the previous and next icons or the navigation of the mobile carousel
MLCV.Spotlight.MobileCarouselControls = function () {

    var selector = {
        playlistContainer: '.c-alumni__playlist-container.c-alumni__playlist-container',
        prevNavigation: '.ml-playlist-nav.prev',
        nextNavigation: '.ml-playlist-nav.next',
        thumbnail: '.c-alumni__thumbnail',
        activeThumbnail: '.c-active-thumbnail',
        thumbnailImg: '.c-alumni__playlist-container .c-alumni__thumbnail img'
    };


        var prev = jQuery(selector.playlistContainer).find(selector.prevNavigation);
        var next = jQuery(selector.playlistContainer).find(selector.nextNavigation);
    

    if (MLCV.Spotlight.IsMobile()) {
        prev.on('click', function (e) {
            if (MLCV.Spotlight.IsMobile()) {

                jQuery(selector.thumbnail).find(selector.activeThumbnail).parent().prev().click();
       
                MLCV.Spotlight.IsActiveThumbnail('previous');

            } 
            e.stopImmediatePropagation();

        });

        next.on('click', function (e) {
            if (MLCV.Spotlight.IsMobile()) {
                jQuery(selector.thumbnail).find(selector.activeThumbnail).parent().next().click();
                MLCV.Spotlight.IsActiveThumbnail('next');
            }
            e.stopImmediatePropagation();

        });

        //Spotlight Swipe
        var ts;
        jQuery(selector.thumbnailImg).on('touchstart', function (e) {
            e.preventDefault();
            ts = e.originalEvent.touches[0].clientX;

        });

        if (MLCV.Spotlight.IsMobile()) {
            jQuery(selector.thumbnailImg).on('touchend', function (e) {
                if (e.cancelable) {
                    e.preventDefault();
                }
                var te = e.originalEvent.changedTouches[0].clientX;
                if (ts > te + 5) {
                    next.click();
                    e.stopImmediatePropagation();
                } else if (ts < te - 5) {
                    prev.click();
                    e.stopImmediatePropagation();
                }


            });

        } else {
            jQuery(selector.thumbnailImg).off('touchend');
            jQuery(selector.thumbnailImg).off('touchstart');
        }

    }
};

MLCV.Spotlight.DesktopCarouselControls = function () {

    var selector = {
        playlistContainer: '.c-alumni__playlist-container.c-alumni__playlist-container',
        prevNavigation: '.ml-playlist-nav.prev',
        nextNavigation: '.ml-playlist-nav.next',
        thumbnail: '.c-alumni__thumbnail',
        activeThumbnail: '.c-active-thumbnail',
    };


        var prev = jQuery(selector.playlistContainer).find(selector.prevNavigation);
        var next = jQuery(selector.playlistContainer).find(selector.nextNavigation); 

    MLCV.Spotlight.NextDesktopControl();
    MLCV.Spotlight.PrevDesktopControl();

    prev.click(function () {
        MLCV.Spotlight.PrevDesktopControl();
        MLCV.Spotlight.NextDesktopControl();
    });

    next.click(function () {
        MLCV.Spotlight.PrevDesktopControl();
        MLCV.Spotlight.NextDesktopControl();
    });
    


};

MLCV.Spotlight.PrevDesktopControl = function () {
    var selector = {
        playlistContainer: '.c-alumni__playlist-container.c-alumni__playlist-container',
        prevNavigation: '.ml-playlist-nav.prev',
        nextNavigation: '.ml-playlist-nav.next',
        thumbnail: '.c-alumni__thumbnail',
        activeThumbnail: '.c-active-thumbnail',
    };


    var prev = jQuery(selector.playlistContainer).find(selector.prevNavigation);
    hasPrev = jQuery(selector.thumbnail).find(selector.activeThumbnail).parent().prev().length != 0;

    if (hasPrev) {
        prev.removeClass('hidden').show();
        
    } else {
        prev.hide();
        
    }

};



MLCV.Spotlight.NextDesktopControl = function () {
    var selector = {
        playlistContainer: '.c-alumni__playlist-container.c-alumni__playlist-container',
        prevNavigation: '.ml-playlist-nav.prev',
        nextNavigation: '.ml-playlist-nav.next',
        thumbnail: '.c-alumni__thumbnail',
        activeThumbnail: '.c-active-thumbnail',
    };

    
    var next = jQuery(selector.playlistContainer).find(selector.nextNavigation);
        hasNext = jQuery(selector.thumbnail).find(selector.activeThumbnail).parent().next().length != 0;

    if (hasNext) {
        next.removeClass('hidden').show();
        
    } else {
        next.hide();
        
    }



};


MLCV.Spotlight.OnResize = function () {
    jQuery(window).on('resize', function () {
     
        var selector = {
            thumbnail: '.ml-media-block.c-alumni__thumbnail',
            thumbnailImg: '.c-alumni__playlist-container .c-alumni__thumbnail img',
        };


        if (jQuery(window).width() <= 640) {
           
            MLCV.Spotlight.MobileCarouselControls();
            MLCV.Spotlight.ApplyMobileCarousel();
            MLCV.Spotlight.IsActiveThumbnail();

        }

        else {

            MLCV.Spotlight.DesktopCarouselControls();
            
            jQuery(selector.thumbnail).each(function () {
                jQuery(this).css('display', '');
            });

            MLCV.HorizontalPlaylist.eventHandlers;

            jQuery(selector.thumbnailImg).off('touchend');
            jQuery(selector.thumbnailImg).off('touchstart');
            
           
        }

    });
};

//-----------ALUMNI CAROUSEL END -------------//

jQuery(document).ready(function () {
    MLCV.InfoGraphicModule.AttachEventHandlers();
    MLCV.CareerTrajectory.AttachEventHandlers();
    MLCV.Internationalization.AttachEventHandlers();
    MLCV.PublicationSeries.HideItems();
    MLCV.PublicationSeries.Init();
    MLCV.TeamListPrintViewLayout.Init();
   
    //MLCV.TabsModuleHelper.EventHandlers();
    //MLCV.TabsModuleHelper.VisibilityHandlers();
	var deviceAgent = navigator.userAgent.toLowerCase();
    var isIPad = deviceAgent.match(/(ipad)/);
    
    //on scroll, pause only videos who don't have the attribute of "playsinline"
    jQuery(document).scroll(function () {
        for (var player in mejs.players) {
            var id = "#" + mejs.players[player].id;
            //IOS safari triggers the scroll event thus we have an IF statement here.
            if (typeof jQuery(id + " video").attr("playsinline") !== "undefined")
                mejs.players[player].media.pause(); //pause
        }

    });
	if (jQuery(".collapse-box").find(".teamlist-print").length > 0) {
        jQuery('.teamlist-print:not(.secondarylistclass-print)').show();
        jQuery('.keycontact-print:not(.secondarylistclass-print)').hide();

	} else {
        jQuery('.keycontact-print:not(.secondarylistclass-print)').show();
        jQuery('.teamlist-print:not(.secondarylistclass-print)').hide();
    }
	if (!isMobile.any || isIPad) {
		if ((jQuery('.col-r .member-block .thumbnail-detail').find('.practices').length > 0) || (jQuery('.col-r .member-block .thumbnail-detail').find('.loc').length > 0)) {
			jQuery('.col-r .member-block .thumbnail-detail').each(function () {
				if ((jQuery(this).height() > 40) && ((jQuery(this).height() <= 75))) {
					jQuery(this).css("padding-top", "22px");
				}
				if ((jQuery(this).height() >= 76) && ((jQuery(this).height() <= 94))) {
					jQuery(this).css("padding-top", "15px");
				}
				if (jQuery(this).height() > 95) {
					jQuery(this).css("padding-top", "0px");
				}
			});
		}
		if ((jQuery('.collapse-expand-a').find('.special-topic.teamlist').length > 0) && (!jQuery('.special-topic.teamlist').find('.col3.show-image').length > 0)) {
			jQuery('.collapse-expand-a').css("width", "480.297px");
		}

	}
	if (!isMobile.any) {
		jQuery('.collapse-expand-a .special-topic.teamlist .team-memberblock .member-block .col3.show-image').each(function () {
			if ((jQuery('.collapse-expand-a .special-topic.teamlist .team-memberblock .member-block .thumbnail-detail').height() > 87)) {
				jQuery(this).css("height", "250px");
			} else {
				jQuery(this).css("height", "225px");
			}
		});
    }

});

jQuery(window).on("load", function () { 
    setTimeout(function () {
        MLCV.MediaModule.Init();
        MLCV.CarouselModule.init();
        MLCV.VerticalPlaylist.init();
        MLCV.HorizontalPlaylist.init();
        MLCV.Renderer.init();
        MLCV.HorizontalPlaylist.refresh();

        if (jQuery('.c-alumni__container').length != 0) {
            MLCV.Spotlight.Init();
            MLCV.Spotlight.OnResize();
        }

        var oneTrustElm = '#onetrust-banner-sdk';
        var deviceAgent = navigator.userAgent.toLowerCase();
        var isIOS = deviceAgent.match(/(\(ipod|\(iphone|\(ipad)/) && !window.MSStream;

        if (isMobile.any && isIOS && jQuery(oneTrustElm).length > 0) {
            
            var elementTop = jQuery(oneTrustElm).offset().top;
            var windowHeight = jQuery(window).innerHeight();
            var elementHeight = $(oneTrustElm).outerHeight();

            if (elementTop % 1 != 0) {
                windowHeight = windowHeight + 1;
            }
            
            if ((windowHeight) < (elementTop + elementHeight)) {
                jQuery(oneTrustElm).animate({
                    bottom: ''
                }, 1000);
            }
            
        }

    }, 1000);
}); //vanilla